import {
  JFWConfirmPasswordSchema,
  JFWNewPasswordSchema,
  isPasswordMatch,
  passwordMatchRefineParams,
} from 'common-types';
import * as z from 'zod';

export const PasswordFormSchema = z
  .object({
    newPassword: JFWNewPasswordSchema,
    confirmNewPassword: JFWConfirmPasswordSchema,
  })
  .strict()
  .refine(isPasswordMatch, passwordMatchRefineParams);

export type PasswordForm = z.infer<typeof PasswordFormSchema>;
type PasswordFormFormattedErrors = z.inferFormattedError<
  typeof PasswordFormSchema
>;

/*
const form = new FormGroup({
  newPassword: new FormControl({ value: '', disabled: false }, {
    nonNullable: true,
    // validators: [ZodFormUtilities.zodFormControlValidator(PasswordFormSchema, 'newPassword')],
  }),
  confirmNewPassword: new FormControl({ value: '', disabled: false }, {
    nonNullable: true,
    // validators: [ZodFormUtilities.zodFormControlValidator(PasswordFormSchema, 'confirmNewPassword')],
  }),
  test: new FormGroup({
    test1: new FormControl({ value: 'Test 1', disabled: false }, {
      nonNullable: true,
    }),
    test2: new FormControl({ value: 'Test 2', disabled: false }, {
      nonNullable: true,
    }),
  })
},
  // [ZodFormUtilities.zodFormGroupValidator(PasswordFormSchema)]
);


const result = PasswordFormSchema.safeParse({
  newPassword: 'test',
  confirmNewPassword: 'test',
  test: {
    test1: 'test1!',
    test2: 'test2!',
  }
});


if (!result.success) {
  const { fieldErrors, formErrors } = result.error.flatten();
  const fieldErrorsKeys = Object.keys(fieldErrors);

  const formatted: PasswordFormFormattedErrors = result.error.format();
  formatted.test?.test1?._errors
  formatted.confirmNewPassword?._errors

  // const keyIs_Errors = <U extends keyof PasswordFormFormattedErrors>(key: U ): key is z.ZodFormattedError<U, string> => {
  //   return key === '_errors';
  // }

  const setErrorsOnControls = <A>(errors: z.ZodFormattedError<A>, path: string[]) => {
    const keys = Object.keys(errors) as (keyof typeof errors)[]; // this is the list of keys at this level of the object
    const numKeys = keys.length; // this is the number of keys at this level of the object

    // this should never happen because error object should always have at least one key
    if (numKeys === 0) {
      return;
    }

    // if there is more than one key, then there are nested errors, so set errors for current level and recurse
    if (numKeys > 1) {
      // set errors for current level
      errors._errors?.forEach((errorMessage: string, index) => {
        const control = form.get(path); // get the control from the form using the path
        if (control) {
          const currentErrors = control.errors;
          const isLast = index === fieldErrorsKeys.length - 1;
          const newErrors = { ...currentErrors, schemaFieldErrors: errors._errors };
          return control.setErrors({ ...newErrors }, { emitEvent: isLast });
        }
        return;
      });

      const { _errors: notUsedHere, ...rest1 } = errors;
      const rest = rest1 as Omit<typeof rest1, '_errors'>;
      const _errors = errors._errors;
      type Without_Errors<T> = Omit<T, '_errors'>;
      const restKeys = Object.keys(rest) as (keyof typeof rest)[]; // this is the list of keys at this level of the object
      // recurse
      restKeys.forEach((key) => {
        const newPath = [...path, key];
        const nestedErrors = rest[key]
      })
    }

    // if there is only one key, then there are no nested errors, so set errors for current level and return
    if (numKeys === 1) {
      // set errors for current level
      errors._errors?.forEach((errorMessage: string, index) => {
        const control = form.get(path); // get the control from the form using the path
        if (control) {
          const currentErrors = control.errors;
          const isLast = index === fieldErrorsKeys.length - 1;
          const newErrors = { ...currentErrors, schemaFieldErrors: errors._errors };
          return control.setErrors({ ...newErrors }, { emitEvent: isLast });
        }
        return;
      });
      return;
    }



  }



}

*/

// export const PasswordFormSchema = z
//   .object(
//     {
//       newPassword: z
//         .string({ invalid_type_error: 'Password is required' }) // catch invalid type error like null
//         .min(1, 'Password is required'),
//       confirmNewPassword: z
//         .string({ invalid_type_error: 'Confirm Password is required' }) // catch invalid type error like null
//         .min(1, 'Confirm Password is required'),
//     }
//     // { invalid_type_error: 'Invalid Type' }
//   )
//   .strict()
//   .refine((data) => data.newPassword === data.confirmNewPassword, {
//     message: 'Passwords must match',
//     path: ['confirmNewPassword'],
//   });

// export type PasswordForm = z.infer<typeof PasswordFormSchema>;

// export const PasswordDataSchema = PasswordFormSchema
//   .refine((data) => data.newPassword === data.confirmNewPassword, {
//     message: 'Passwords must match',
//     path: ['confirmNewPassword'],
//   });

// const obj = PasswordDataSchema._def.schema

// export type PasswordModel = z.infer<typeof PasswordDataSchema>;
