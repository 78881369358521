import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import {
  Auth,
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
} from '@angular/fire/auth';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import {
  RecoverEmailData,
  RecoverEmailResponse,
  SendEmailVerificationData,
  SendResetPasswordData,
  SendResetPasswordResponse,
  SendSuccessfulResetPasswordData,
  Site,
} from 'common-types';
import { from, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthEmailActionsService {
  private auth: Auth = inject(Auth);

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient
  ) {}

  async handleVerifyEmail(actionCode: string) {
    // Try to apply the email verification code.
    try {
      const result = await applyActionCode(this.auth, actionCode);
      // Email address has been verified.  Check if user is signed in
      const user = this.auth.currentUser;
      if (user) {
        await user.reload();
      }
      return;
    } catch (error) {
      // Code is invalid or expired.
      console.error('error:', error);
      // Handle the error in the component.
      throw error;
    }
  }

  handleRecoverEmail(actionCode: string) {
    console.log('starting handleRecoverEmail with actionCode:', actionCode);
    const url = `${this.environment.update_user_account_server_url}/recoverEmail`;
    console.log('url:', url);
    // Try to apply the email verification code.
    const data: RecoverEmailData = {
      oobCode: actionCode,
    };

    return this.httpClient
      .post<RecoverEmailResponse>(url, data, requireAppCheck)
      .pipe(tap((response) => console.log('response:', response)));
  }

  sendEmailVerificationApi(site: Site, continueUrl?: string) {
    const user = this.auth.currentUser;
    if (!user) {
      throw new Error('User is not authenticated.');
    }

    const { email } = user;

    if (!email) {
      throw new Error('User does not have an email address.');
    }

    const url = `${this.environment.update_user_account_server_url}/sendEmailVerification`;

    const data: SendEmailVerificationData = {
      email,
      site,
      continueUrl,
    };

    return this.httpClient.post<void>(url, data, requireAppCheck);
  }

  sendResetPassword(email: string, site: Site, continueUrl?: string) {
    const url = `${this.environment.update_user_account_server_url}/sendResetPassword`;

    const data: SendResetPasswordData = {
      email,
      site,
      continueUrl,
    };

    return this.httpClient.post<SendResetPasswordResponse>(
      url,
      data,
      requireAppCheck
    );
  }

  checkResetPasswordActionCode(actionCode: string) {
    return checkActionCode(this.auth, actionCode);
  }

  // Check the actionCode using checkResetPasswordActionCode() before calling this method.
  // This method assumes that the actionCode is valid. An error will be thrown if the actionCode is invalid.
  // The email address is returned from the actionCodeInfo object returned by checkResetPasswordActionCode(),
  // which is then passed into this function to be used for sendSuccessfulResetPassword().
  handleResetPassword(actionCode: string, newPassword: string, email: string) {
    // Try to apply the email verification code.
    return from(confirmPasswordReset(this.auth, actionCode, newPassword)).pipe(
      tap((result) => {
        // Password reset has been confirmed and new password updated.
        const user = this.auth.currentUser;
        if (user) this.auth.currentUser?.reload();
      }),
      switchMap((result) => this.sendSuccessfulResetPassword(email))
    );
  }

  sendSuccessfulResetPassword(email: string) {
    const url = `${this.environment.update_user_account_server_url}/sendSuccessfulResetPassword`;

    const data: SendSuccessfulResetPasswordData = {
      email,
    };

    return this.httpClient.post<void>(url, data, requireAppCheck);
  }

  testEmailChange() {
    return this.httpClient.get<void>(
      `${this.environment.update_user_account_server_url}/testEmailChange`,
      {}
    );
  }
}
