import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { UserEvent, EcomUserEventV2Types as v2 } from 'common-types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EcomUserEvent_V2_ApiService {
  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private readonly apiUrl = '/api/2/userEvent';

  constructor(private httpClient: HttpClient) {}

  /******************************************************
   *                       CREATE                       *
   ******************************************************/

  /*******************************************************
   *                      READ                           *
   *******************************************************/
  public getUserEventByEventIdAndUserId(
    id: string,
    userId: string
  ): Observable<UserEvent[]> {
    const apiUrl = `${this.apiUrl}/eventId/${id}/userId/${userId}`;

    return this.httpClient.get<v2.GetUserEventByEventIdAndUserIdSuccessResponse>(
      apiUrl,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  public getUserEventsByUserId(userId: string): Observable<UserEvent[]> {
    const apiUrl = `${this.apiUrl}/user/${userId}`;

    return this.httpClient.get<v2.GetUserEventsByUserIdSuccessResponse>(
      apiUrl,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
    // .pipe(
    //   map((events) =>
    //     events.sort(
    //       (a, b) =>
    //         new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime()
    //     )
    //   ),
    //   share()
    // );
  }

  /********************************************************
   *                      UPDATE                          *
   * ******************************************************/
  public updateUserEvent(userEvent: UserEvent) {
    const apiUrl = `${this.apiUrl}/update`;

    const data: v2.UpdateUserEventData = { ...userEvent };

    return this.httpClient.post<v2.UpdateUserEventSuccessResponse>(
      apiUrl,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /********************************************************
   *                     DELETE                           *
   ********************************************************/

  public deleteAllUserEventsByEventId(eventId: string): Observable<true> {
    const apiUrl = `${this.apiUrl}/delete/${eventId}`;

    return this.httpClient.delete<v2.DeleteAllUserEventByEventIdSuccessResponse>(
      apiUrl,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  public deleteUserEventByMemberIdEventId(
    memberId: string,
    eventId: string
  ): Observable<true> {
    const apiUrl = `${this.apiUrl}/deleteByMemberEvent/${memberId}/${eventId}`;

    return this.httpClient.delete<v2.DeleteUserEventByMemberIdEventIdSuccessResponse>(
      apiUrl,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }
}
