import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { EcomInviteV3Types as v3 } from 'common-types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EcomInvite_V3_ApiService {
  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private readonly apiUrl = '/api/3/invite';

  constructor(private httpClient: HttpClient) {}

  public verifyEventInvite(
    eventId: string,
    emailAddress: string
  ): Observable<v3.VerifyEventInviteSuccessResponse> {
    const apiUrl = `${this.apiUrl}/verifyEventInvite/`;
    const data: v3.VerifyEventInviteData = {
      eventId: eventId,
      emailAddress: emailAddress,
    };
    return this.httpClient.post<v3.VerifyEventInviteSuccessResponse>(
      apiUrl,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }
}
