import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject, Injectable, isDevMode, PLATFORM_ID } from '@angular/core';
import { keepUnstableUntilFirst } from '@angular/fire';
import { AppCheck, getToken } from '@angular/fire/app-check';
import {
  catchError,
  from,
  iif,
  Observable,
  of,
  switchMap,
  throwError,
} from 'rxjs';
import { AppCheckService } from '../../services/app-check';
import { REQUIRE_APP_CHECK, requireAppCheck } from './require-app-check';

const DEBUG = {
  allRequests: isDevMode() && false, // set to true to enable App Check on all requests (use to test App Check is successfully getting tokens).  You should see errors in the console if App Check is not working.
  forceRefresh: isDevMode() && false, // USE THIS SPARINGLY.  set to true to force refresh the App Check token on every request.  Since App Check tokens are cached for 1 hour, this is useful for testing.
  error: isDevMode() && false, // set to true to simulate an error when getting the AppCheck token (without actually calling the the App Check token api).  This is useful for testing.
};

export const appCheckInterceptor: HttpInterceptorFn = (req, next) => {
  const isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  const appCheck = inject(AppCheck);
  // const appCheckService = inject(AppCheckService);

  // console.log("appCheckService available in appCheckInterceptor.")

  const { required, forceRefresh } = req.context.get(REQUIRE_APP_CHECK);

  // console.log(`%c AppCheckInterceptor: ${req.method}:${req.url}, required: ${required}, forceRefresh: ${forceRefresh}`, 'color: white; background-color: blue;');

  if (!required && !DEBUG.allRequests) {
    return next(req);
  }
  const date = new Date();
  const display =
    date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    }) +
    ' ' +
    date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    }) +
    '.' +
    date.getMilliseconds();

  console.log(
    `%c ${display} -- ${req.method}:${req.url} requires AppCheck`,
    'color: white; background-color: #00A36C;'
  );
  console.log(
    `%c ${display} -- ${req.method}:${req.url} forceRefresh is ${
      forceRefresh ? 'ON' : 'OFF'
    }.`,
    'color: white; background-color: #00A36C;'
  );

  if (DEBUG.forceRefresh)
    console.warn(
      `%c ${display} -- App Check DEBUG.forceRefresh is ON.  Use sparingly.  This will force refresh the App Check token on every request.`,
      'color: white; background-color: #00A36C; font-size: 1.1em;'
    );

  if (DEBUG.error)
    console.warn(
      `%c DEBUG.error is ON.`,
      'color: white; background-color: #00A36C; font-size: 1.1em;'
    );
  return iif(
    () => DEBUG.error,
    throwError(
      () =>
        'DEBUG.error is ON.  Simulating an error when getting the AppCheck token (without actually calling the the App Check token api).'
    ),
    from(getToken(appCheck, forceRefresh))
  )
    .pipe(
      keepUnstableUntilFirst,
      catchError((error) => {
        if (DEBUG.error) {
          console.error('SIMULATED App Check token error', error);
        } else {
          console.error('App Check token error: ', error);
        }

        if (isBrowser) {
          /// Using a confirm here because it is a blocking dialog that will pause the code execution until the user responds
          /// and not create an infinite reload loop.
          if (
            confirm(
              'Sorry, there was an error with AppCheck.  The page needs to refresh.'
            )
          ) {
            window.location.reload();
          }
        }
        // allow the request to continue without the AppCheck token.  The api will return an error if the token is required.
        return of(null);

        /* if (DEBUG.allRequests || DEBUG.error) {
          if (confirm(`DEBUG.allRequests or DEBUG.error is on with forceRefresh set to ${forceRefresh}.  App Check token error: ${error}.  The page needs to refresh.`)) {
            location.reload();
          }
        }
        // console.error("Error getting App Check token: ", error);
        return throwError(() => error); // rethrow the error
        // throw error; // rethrow the error
        // return next.handle(req); */
      })
    )
    .pipe(
      switchMap((appCheckToken) => {
        const date = new Date();
        const display =
          date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
          }) +
          ' ' +
          date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
          }) +
          '.' +
          date.getMilliseconds();
        if (appCheckToken) {
          console.log(`${display} -- appCheckToken received`);
          // console.log(appCheckToken.token);
          const authReq = req.clone({
            headers: req.headers.set(
              'X-Firebase-AppCheck',
              appCheckToken.token
            ),
          });
          return next(authReq);
        } else {
          console.log(
            `${display} -- appCheckToken not received, continuing without AppCheck token on request`
          );
          return next(req);
        }
      })
    );
};




/**
 * Interceptor to add the App Check token to the headers of an http request.
 * The AppCheck token will only be added to requests that have the {@link REQUIRE_APP_CHECK} HttpContextToken set to true.
 * This is done by using the {@link requireAppCheck} object as part of the options object for the http request.
 *
 * See our full [App Check Documentation](../../../../../documentation/app-check.md) for more details.
 */

@Injectable()
export class AppCheckInterceptor implements HttpInterceptor {
  constructor(
    private appCheckService: AppCheckService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    // REMOVE THIS LINE and uncomment everything to enable App Check
    // const dummyRequest = req.clone({ headers: req.headers.set('X-Firebase-AppCheck', 'dummy-token')})
    // return next.handle(dummyRequest);


    const { required, forceRefresh } = req.context.get(REQUIRE_APP_CHECK);

    // console.log(`%c AppCheckInterceptor: ${req.method}:${req.url}, required: ${required}, forceRefresh: ${forceRefresh}`, 'color: white; background-color: blue;');



    if (!required && !DEBUG.allRequests) {
      return next.handle(req);
    }
    const date = new Date();
    const display = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) + " " + date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }) + "." + date.getMilliseconds();

    console.log(`%c ${display} -- ${req.method}:${req.url} requires AppCheck`, 'color: white; background-color: #00A36C;');
    console.log(`%c ${display} -- ${req.method}:${req.url} forceRefresh is ${forceRefresh ? "ON" : "OFF"}.`, 'color: white; background-color: #00A36C;');

    if (DEBUG.forceRefresh) console.warn(`%c ${display} -- App Check DEBUG.forceRefresh is ON.  Use sparingly.  This will force refresh the App Check token on every request.`, 'color: white; background-color: #00A36C; font-size: 1.1em;');

    if (DEBUG.error) console.warn(`%c DEBUG.error is ON.`, 'color: white; background-color: #00A36C; font-size: 1.1em;');
    return iif(() => DEBUG.error,
      throwError(() => "DEBUG.error is ON.  Simulating an error when getting the AppCheck token (without actually calling the the App Check token api)."),
      this.appCheckService.getAppCheckToken(forceRefresh).pipe(
        switchMap(appCheckToken => {
          const date = new Date();
          const display = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) + " " + date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }) + "." + date.getMilliseconds();
          console.log(`${display} -- appCheckToken:  ${appCheckToken.token}`);
          if (appCheckToken) {
            const authReq = req.clone({ headers: req.headers.set('X-Firebase-AppCheck', appCheckToken.token) });
            return next.handle(authReq);
          } else {
            return next.handle(req);
          }
        }),
      )
    ).pipe(
      catchError(err => {
        if (DEBUG.allRequests || DEBUG.error) {
          if (confirm(`DEBUG.allRequests or DEBUG.error is on with forceRefresh set to ${forceRefresh}.  App Check token error: ${err}.  The page needs to refresh.`)) {
            location.reload();
          }
        }
        // console.error("Error getting App Check token: ", err);
        return throwError(() => err); // rethrow the error
        // throw err; // rethrow the error
        // return next.handle(req);
      })
    );

  }
}

