import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import {
  generateUuid,
  getCartSubtotal,
  getMemberSubtotal,
  getPriorShippingAddress,
} from 'business-logic';
import {
  BillingAddress,
  Cart,
  CartProcessorStatus,
  CartShippingAddressForm,
  CheckoutState,
  EcommerceMainEnvironment,
  Event,
  EventMember,
  MemberCart,
  Promo,
  PurchaseItem,
  ShippingAddress,
  StyleGroup,
} from 'common-types';
import dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs';
import { AuthV1Service } from '../auth/authV1/authV1.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private checkoutState: CheckoutState = {
    payingForVisible: false,
    payingForReady: false,
    payingForExpanded: false,
    payingForError: false,
    payingForShowError: false,
    measurementsVisible: false,
    measurementsReady: false,
    measurementsExpanded: false,
    measurementsShowError: false,
    measurementsError: false,
    shippingVisible: false,
    shippingReady: false,
    shippingExpanded: false,
    shippingError: false,
    shippingShowError: false,
    paymentsVisible: false,
    paymentsReady: false,
    paymentsExpanded: false,
    paymentsError: false,
    paymentsShowError: false,
    orderDetailsVisible: false,
    orderDetailsReady: false,
    orderDetailsExpanded: false,
    orderDetailsError: false,
  };

  private checkoutStateSubject = new BehaviorSubject<CheckoutState>(
    this.getStartingCheckoutState()
  );

  public checkoutState$ = this.checkoutStateSubject.asObservable();

  public checkoutCartSubject = new BehaviorSubject<Cart | undefined>(
    this.getStartingCartSubject()
  );

  public canProceed = new BehaviorSubject<boolean>(false);
  private MAX_CART_LOCAL_STORAGE_AGE =
    this.environment.max_selected_cart_local_storage_age_secs;

  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  constructor(
    @Inject('environment') private environment: EcommerceMainEnvironment,
    private authV1Service: AuthV1Service
  ) {}

  public resetCheckoutState() {
    localStorage.removeItem('checkout_state');
    // console.log('Resetting Checkout State');
    this.checkoutState.payingForVisible = false;
    this.checkoutState.payingForReady = false;
    this.checkoutState.payingForExpanded = false;
    this.checkoutState.measurementsVisible = false;
    this.checkoutState.measurementsReady = false;
    this.checkoutState.measurementsExpanded = false;
    this.checkoutState.shippingVisible = false;
    this.checkoutState.shippingReady = false;
    this.checkoutState.shippingExpanded = false;
    this.checkoutState.paymentsVisible = false;
    this.checkoutState.paymentsReady = false;
    this.checkoutState.paymentsExpanded = false;
    // this.checkoutState.orderDetailsVisible = false;
    this.checkoutState.orderDetailsReady = false;
    this.checkoutState.orderDetailsExpanded = false;
    this.checkoutStateSubject.next(this.checkoutState);
  }

  private getStartingCartSubject(): Cart | undefined {
    // if (this.getCart() !== undefined){
    //   return
    // }
    return this.getCart();
  }

  /** Pushes the result of getStartingCheckoutState into checkoutStatusSubject */
  public initializeCheckoutState() {
    this.checkoutStateSubject.next(this.getStartingCheckoutState());
  }

  public getStartingCheckoutState(): CheckoutState {
    if (!this.isBrowser) {
      return this.checkoutState;
    }
    //return this.startingCheckoutState;

    // console.log('getStartedCheckoutState');

    if (localStorage.getItem('checkout_state') !== null) {
      // console.log('Found prior checkout_state');
      // console.log(localStorage.getItem('checkout_state'));

      let savedCheckoutState: CheckoutState = JSON.parse(
        localStorage.getItem('checkout_state')!
      );

      return savedCheckoutState;
    } else {
      return this.checkoutState;
    }
  }

  public cleanCartWithNewEvent(newEventId: string) {
    if (localStorage.getItem('cart') !== null) {
      let cart: Cart = JSON.parse(localStorage.getItem('cart')!);

      if (cart.eventId === newEventId) {
        return;
      }

      let purchaseItems = cart.cartPurchaseItems;

      let newCart: Cart = {
        id: '1', // We're just putting this here because id is *not* optional right now. It gets resolved with the creation/update steps
        eventId: newEventId,
        eventName: '',
        eventType: '',
        eventDate: 0,
        userId: undefined,
        members: undefined,
        payment: {
          cardName: '',
          cardType: '',
          cardNumber: '',
          cvv: '',
          expirationDate: new Date(0),
        },
        subtotal: 0,
        damageWaiver: 0,
        taxes: 0,
        shippingCost: 0,
        discounts: 0,
        cartPurchaseItems: purchaseItems,
        cartProcessorStatus: CartProcessorStatus.PENDING,
      };

      this.setCartInLocalStorage(newCart);
    }

    return;
  }

  public updateCheckoutState(state: Partial<CheckoutState>) {
    const currentState = this.checkoutStateSubject.getValue();
    const newState = { ...currentState, ...state };
    this.checkoutStateSubject.next(newState);
    localStorage.setItem('checkout_state', JSON.stringify(newState));
  }

  private isCartLocalStorageOutdated(): boolean {
    let dateDiff: number = 999999999; // set default diff to extreme

    // If 'cart_updated' exists in local storage, calculate age
    if (localStorage.getItem('cart_updated') !== null) {
      let updateDate = dayjs(localStorage.getItem('cart_updated'));
      let currentDate = dayjs(Date.now());

      dateDiff = currentDate.diff(updateDate) / 1000;
      // console.log('cart dateDiff: ' + dateDiff);
    }

    return dateDiff > this.MAX_CART_LOCAL_STORAGE_AGE;
  }

  public getCartFromLocalStorage(
    byPassExpirationCheck: boolean = false
  ): Cart | undefined {
    if (!this.isBrowser) return undefined;

    let cart: Cart | undefined;

    if (localStorage.getItem('cart') === null) {
      // console.log('NO CART CACHE');
      return undefined;
    }

    if (!byPassExpirationCheck && this.isCartLocalStorageOutdated()) {
      // console.log('CACHE OUTDATED');
      return undefined;
    }

    return JSON.parse(localStorage.getItem('cart')!);
  }

  public setCartInLocalStorage(cart: Cart | undefined): void {
    if (cart === undefined) {
      return undefined;
    }

    localStorage.setItem('cart', JSON.stringify(cart));
    localStorage.setItem('cart_updated', dayjs(Date.now()).toString());

    this.checkoutCartSubject.next(cart);
  }

  public clearCartCache(): void {
    localStorage.removeItem('cart');
    localStorage.removeItem('cart_updated');
    localStorage.removeItem('payingFor');
    this.checkoutCartSubject.next(undefined);
    //this.checkoutCartSubject.complete();
  }

  public async getCartAsync() {
    return this.getCartFromLocalStorage();
  }

  private updateCartPromise(cart: Cart): Promise<Cart | undefined> {
    return new Promise((resolve, reject) => {
      if (cart !== undefined) {
        this.setCartInLocalStorage(cart);
      }

      resolve(cart);
    });
  }

  public deleteUserCart(eventId: string): Promise<Cart | undefined> {
    return new Promise((resolve, reject) => {
      this.clearCartCache();

      resolve(undefined);
    });
  }

  private createPriorShippingDetailsMap(
    priorMembers: Array<MemberCart> | undefined = undefined
  ): Map<string, ShippingAddress> {
    let membersShippingDetails = new Map<string, ShippingAddress>();
    if (priorMembers) {
      priorMembers.forEach((member: any) => {
        membersShippingDetails.set(member.id, member.shipping.address);
      });
    }
    return membersShippingDetails;
  }

  public async buildCart(
    event: Event,
    priorCart: Cart | undefined = undefined
  ): Promise<Cart | undefined> {
    return new Promise((resolve, reject) => {
      this.createCartFromSelectedEventPromise(event, priorCart).then((cart) => {
        if (cart !== undefined && cart !== null) {
          this.updateCartLooks(event);
          this.setCartInLocalStorage(cart);
          resolve(cart);
        } else {
          resolve(undefined);
        }
      });
    });
  }

  private getSessionPayingForMembers(): string[] {
    const payingForMembers = JSON.parse(localStorage.getItem('payingFor')!);
    if (payingForMembers == undefined) {
      return [];
    }
    return payingForMembers;
  }

  private async createCartFromSelectedEventPromise(
    event: Event,
    priorCart: Cart | undefined = undefined
  ): Promise<Cart | undefined> {
    // console.log('createCartFromSelectedEventPromise() Prior Cart');
    // console.log('priorCart', priorCart);
    // console.log('event', event);
    return new Promise((resolve, reject) => {
      const currentUserId = this.authV1Service.getCurrentUser()?.uid;
      console.log('Creating Cart with currentUser', currentUserId);

      let sessionSelectedMembers = this.getSessionPayingForMembers();

      console.log('sessionSelectedMembers:' + sessionSelectedMembers);
      let payingForMembers = event.members.filter((member: EventMember) => {
        return (
          currentUserId &&
          sessionSelectedMembers.includes(member.id!) &&
          (member.memberProgress === undefined ||
            member.memberProgress.paid === undefined)
        );
      });
      if (payingForMembers.length <= 0) {
        // alert('An error occurred while updating your cart. Please try again.');
      }

      // console.log('payingForMembers', payingForMembers);

      let priorMembers = priorCart?.members;

      let priorPaymentRefResponse = priorCart?.paymentRefResponse;

      let priorBillingAddress = priorCart?.billingAddress;

      let priorShippingDetails =
        this.createPriorShippingDetailsMap(priorMembers);

      let cartMembers: Array<MemberCart> = [];

      payingForMembers.forEach((member) => {
        let subTotal = getMemberSubtotal(member);

        let priorShippingAddress: ShippingAddress | undefined;

        let memberEventAddress = member.shippingAddress;

        let shippingAddress: ShippingAddress = {
          name: '',
          streetAddress1: '',
          streetAddress2: '',
          city: '',
          state: '',
          zip: '',
          phone: '',
        };

        if (member.id !== undefined) {
          priorShippingAddress = getPriorShippingAddress(member.id, priorCart);
        }

        if (memberEventAddress === undefined) {
          memberEventAddress = shippingAddress;
        }

        if (priorShippingAddress !== undefined) {
          member.shippingAddress = priorShippingAddress;
        }

        let damageWaiverFee: number = this.getDamageWaiverFee(member);

        cartMembers.push({
          id:
            member.id !== undefined
              ? member.id
              : (Math.random() * 100.0).toString(),
          member: member,
          promos: [],
          damageWaiver: damageWaiverFee,
          subtotal: subTotal,
          taxes: -1,
          total: 0,
          shipping: {
            //address: shippingAddress,
            service: '',
            cost: 0,
          },
        });

        // var sub = this.taxService.estimateTax().subscribe({
        //   next: (item) => {
        //     let self = this;
        //     console.log(`TAX WAS FOUND: ${item} `);
        //   },
        // });
      });

      let cartSubtotal = 0;
      cartMembers.forEach((memberCart: MemberCart) => {
        cartSubtotal += memberCart.subtotal;
      });

      let cartDamageWaiver = 0;
      cartMembers.forEach((memberCart: MemberCart) => {
        cartDamageWaiver += memberCart.damageWaiver;
      });

      let cartTaxes = 0;
      cartMembers.forEach((memberCart: MemberCart) => {
        cartTaxes += Math.max(memberCart.taxes, 0);
      });

      let cartShipping = 0;
      cartMembers.forEach((memberCart: MemberCart) => {
        cartShipping += memberCart.shipping.cost;
      });

      let cartDiscounts = 0;
      cartMembers.forEach((memberCart: MemberCart) => {
        memberCart.promos.forEach((promo: Promo) => {
          if (promo.dollarOff !== undefined) {
            cartDiscounts += promo.dollarOff;
          }
          if (promo.percentOff !== undefined) {
            cartDiscounts += memberCart.subtotal * (promo.percentOff / 100.0);
          }
        });
      });

      /// Get Purchase items from current cart (if exists)
      let currentCart: Cart | undefined = this.getCart();
      let purchaseItems: PurchaseItem[] = [];

      if (
        currentCart !== undefined &&
        currentCart.cartPurchaseItems !== undefined
      ) {
        purchaseItems = currentCart.cartPurchaseItems;
        // console.log('Found purchase items');
        // console.log(purchaseItems);
      }

      let randomGuid = generateUuid();

      let newCart: Cart = {
        //id: '1', // We're just putting this here because id is *not* optional right now. It gets resolved with the creation/update steps
        id: randomGuid,
        eventId: event.id,
        eventDate: event.eventDate,
        eventType: event.eventType,
        eventName: event.eventName,
        userId: currentUserId,
        members: cartMembers,
        payment: {
          cardName: '',
          cardType: '',
          cardNumber: '',
          cvv: '',
          expirationDate: new Date(0),
        },
        subtotal: cartSubtotal,
        damageWaiver: cartDamageWaiver,
        taxes: cartTaxes,
        transferredByDealer: event.dealerInfo?.transferredByDealer?.name ?? '',
        shippingCost: cartShipping,
        discounts: cartDiscounts,
        cartPurchaseItems: purchaseItems,
        commissionAccount: event.dealerId,
        cartProcessorStatus: CartProcessorStatus.PENDING,
      };

      // let priorShippingFormDetails = priorCart?.shippingAddressForms;
      let cleanShippingFormDetails = this.getCleanShippingFormDetails(
        newCart,
        priorCart
      );

      if (cleanShippingFormDetails !== undefined) {
        newCart.shippingAddressForms = cleanShippingFormDetails;
      }

      // Update Cart Subtotal
      newCart.subtotal = getCartSubtotal(newCart);

      //newCart.cartPurchaseItems = purchaseItems;
      newCart.eventLastUpdated = event.lastUpdated;

      newCart.eventName = event.eventName;
      newCart.eventDate = event.eventDate;

      if (priorPaymentRefResponse !== undefined) {
        newCart.paymentRefResponse = priorPaymentRefResponse;
      }

      if (priorBillingAddress !== undefined) {
        newCart.billingAddress = priorBillingAddress;
      }

      resolve(newCart);
    });
  }

  private getDamageWaiverFee(member: EventMember) {
    let foundRental: boolean = false;

    member.memberLook?.styles.forEach((style) => {
      if (style.userCartSelectedStyleGroup === StyleGroup.Rental) {
        foundRental = true;
      }
    });

    if (foundRental) {
      return 10;
    }

    return 0;
  }

  private isCartMember(cart: Cart, memberId: string) {
    let isMember: boolean = false;

    cart.members?.forEach((member) => {
      if (member.id === memberId) {
        isMember = true;
        return;
      }
    });

    return isMember;
  }

  private cleanShippingForm(cart: Cart, addressForm: CartShippingAddressForm) {
    if (cart === undefined || addressForm === undefined) {
      return undefined;
    }

    let validMemberIds: string[] = [];

    addressForm.associatedMemberIds?.forEach((id) => {
      if (this.isCartMember(cart, id)) {
        validMemberIds.push(id);
      }
    });

    addressForm.associatedMemberIds = validMemberIds;

    return addressForm;
  }

  private getCleanShippingFormDetails(
    newCart: Cart,
    priorCart: Cart | undefined
  ): CartShippingAddressForm[] | undefined {
    let shippingForms: CartShippingAddressForm[] = [];

    if (priorCart === undefined) {
      return undefined;
    }
    priorCart.shippingAddressForms?.forEach((address) => {
      let newShippingForm: CartShippingAddressForm | undefined =
        this.cleanShippingForm(newCart, address);

      if (newShippingForm !== undefined) {
        shippingForms.push(newShippingForm);
      }
    });

    if (shippingForms.length > 0) {
      return shippingForms;
    } else {
      return undefined;
    }
  }

  // TODO: This is a synchronous call, only used in a couple places that will be refactored.
  public getCart(): Cart | undefined {
    let cart: Cart | undefined = this.getCartFromLocalStorage(true);
    // let priorCart: Cart | undefined;

    // if (cart !== undefined) {
    //   priorCart = cart;
    // }

    return cart;
  }

  public updateCartMember(cart: Cart | undefined, member: MemberCart) {
    if (cart === undefined || cart.members === undefined) {
      return;
    }

    const memberIndex = cart.members.findIndex((oldMember) => {
      return member.id === oldMember.id;
    });

    cart.members[memberIndex] = member;
    this.setCartInLocalStorage(cart);
  }

  private updateCartMembers(event: Event): Cart | undefined {
    // console.log('updateCartMembers');
    // console.log('event', event);
    let cart: Cart | undefined = this.getCart();

    if (cart !== undefined && cart.members !== undefined) {
      cart.members[0].member = event.members[0];

      this.updateCartPromise(cart).then((data) => {
        cart = data;
      });
    }

    return cart;
  }

  private updateCartLooks(event: Event): Cart | undefined {
    let cart = this.getCart();

    if (cart === undefined) {
      return undefined;
    }

    let foundMember: boolean = false;

    for (let i = 0; i < event.members.length; i++) {
      let member: EventMember = event.members[i];

      if (cart.members !== undefined) {
        let memberIndex = cart.members.findIndex((cartMember) => {
          return member.id === cartMember.member.id;
        });

        if (memberIndex > -1) {
          cart.members[memberIndex].member.memberLook = member.memberLook;
          foundMember = true;
        }
      }
    }

    if (!foundMember) {
      cart = this.updateCartMembers(event);
    }

    if (cart !== undefined) {
      this.updateCartPromise(cart).then((data) => {
        cart = data;
      });
    }

    return cart;
  }

  public getMemberShippingAddressesFromCart(
    cart: Cart
  ): Array<ShippingAddress> {
    let shippingAddresses: ShippingAddress[] = [];
    // Check assigned shipping forms for a checkout member
    let hasShippingForm = cart?.shippingAddressForms?.some(
      (shippingForm: CartShippingAddressForm) => {
        return shippingForm?.associatedMemberIds?.length;
      }
    );
    // If there is a multi form, shipping address found here
    if (
      hasShippingForm &&
      cart?.members !== undefined &&
      cart?.members?.length > 1
    ) {
      cart?.shippingAddressForms?.forEach((shippingAddress) => {
        if (shippingAddress?.associatedMemberIds?.length! > 0) {
          shippingAddresses.push(shippingAddress.shippingAddress);
        }
      });
    } else {
      // Use member object in members array for shipping address otherwise
      cart?.members?.forEach((member) => {
        if (member.member.shippingAddress) {
          shippingAddresses.push(member.member.shippingAddress);
        }
      });
    }
    return shippingAddresses;
  }

  public getBillingAddressFromCart(cart: Cart): BillingAddress {
    if (cart?.billingAddressSameAsShipping) {
      return this.getMemberShippingAddressesFromCart(cart)[0];
    } else {
      return cart?.billingAddress!;
    }
  }

  //////////////////////////////////////////////////////////////
  // DEPRECATED PROPERTIES/METHODS
  //////////////////////////////////////////////////////////////
  /*
  subscription = new Subscription();
  buyCartTemp = new BehaviorSubject<PurchaseItem[] | undefined>(undefined);
  user: firebase.User | null = null;
  savingCart = false;
  user: User | null = null;
  private auth: Auth = inject(Auth);

  public updateCartShippingAddressesForms(cart: Cart | undefined) {
    if (cart === undefined) {
      return;
    }

    //cart.shippingAddressForms
    this.setCartInLocalStorage(cart);
  }

  public updateBuyCartSubject() {
    this.buyCartTemp.next(this.getBuyCart());
  }

  public getBuyCart(): PurchaseItem[] | undefined {
    let cart: Cart | undefined = this.getCart();

    if (cart === undefined) {
      let cartPurchaseItems: PurchaseItem[] = [];
      /// Create empty cart
      let newCart: Cart = {
        eventDate: 0,
        eventId: '',
        eventName: '',
        eventType: '',
        damageWaiver: 0,
        discounts: 0,
        id: 'newCart',
        payment: {
          cardName: '',
          cardType: '',
          cardNumber: '',
          cvv: '',
          expirationDate: new Date(0),
        },
        shippingCost: 0,
        subtotal: 0,
        taxes: 0,
        cartPurchaseItems: cartPurchaseItems,
        cartProcessorStatus: CartProcessorStatus.PENDING,
      };
      //localStorage.setItem('cart', JSON.stringify(newCart));

      newCart.subtotal = getCartSubtotal(newCart);

      this.setCartInLocalStorage(newCart);
      return newCart.cartPurchaseItems;
    }

    return cart.cartPurchaseItems;
  }

  public removeFromBuyCart(cartItem: PurchaseItem, qty: number) {
    let currentCart: PurchaseItem[] | undefined = this.getBuyCart();

    let cart: Cart | undefined = this.getCart();

    if (currentCart === undefined) {
      return true;
    }

    let buyCartIndex = currentCart.findIndex((item) => {
      return (
        item.style.styleCode === cartItem.style.styleCode &&
        item.size == cartItem.size
      );
    });

    if (buyCartIndex > -1) {
      currentCart[buyCartIndex].qty = currentCart[buyCartIndex].qty - qty;

      if (currentCart[buyCartIndex].qty <= 0) {
        currentCart.splice(buyCartIndex, 1);
      }

      if (cart !== undefined && cart?.cartPurchaseItems !== undefined) {
        cart.cartPurchaseItems = currentCart;
        cart.subtotal = getCartSubtotal(cart);
        this.setCartInLocalStorage(cart);
      }

      return true;
    }

    return true;
  }

  public addToBuyCart(cartItem: PurchaseItem, overrideQty: number = 0) {
    let purchaseItems: PurchaseItem[] | undefined = this.getBuyCart();

    let cart: Cart | undefined = this.getCart();

    overrideQty = overrideQty * 1;

    if (purchaseItems === undefined) {
      cart?.cartPurchaseItems?.push(cartItem);

      //localStorage.setItem('cart', JSON.stringify(cart));
      this.setCartInLocalStorage(cart);
      return true;
    }

    let buyCartIndex = purchaseItems.findIndex((item) => {
      return (
        item.style.styleCode === cartItem.style.styleCode &&
        item.size == cartItem.size
      );
    });

    if (buyCartIndex < 0) {
      // console.log('Adding Item to Cart');
      cart?.cartPurchaseItems?.push(cartItem);
      //localStorage.setItem('cart', JSON.stringify(cart));
      if (cart !== undefined) {
        cart.subtotal = getCartSubtotal(cart);
      }

      this.resetCheckoutState();
      this.setCartInLocalStorage(cart);
      return true;
    }

    // console.log('Override Qty:' + overrideQty);

    let item: PurchaseItem = purchaseItems[buyCartIndex];

    if (overrideQty === 0) {
      // console.log(
      //   'Updating Qty from: ' + item.qty + ' to ' + (item.qty + cartItem.qty)
      // );
      item.qty = item.qty + cartItem.qty;
    } else {
      // console.log(
      //   'Overriding Qty from: ' +
      //     Number(item.qty) +
      //     ' to ' +
      //     (Number(item.qty) + Number(overrideQty))
      // );
      item.qty = Number(item.qty) + Number(overrideQty);
    }

    if (cart?.cartPurchaseItems !== undefined) {
      cart.cartPurchaseItems = purchaseItems;
      cart.subtotal = getCartSubtotal(cart);
    }

    //localStorage.setItem('cart', JSON.stringify(cart));
    this.setCartInLocalStorage(cart);
    return true;
  }
  */
}
