import { convertDateToUnixTimestamp } from 'business-logic';
import { EventNameSchema, EventSchema } from 'common-types';
import { z } from 'zod';

const EventDetailsFormSchema = z.object({
  eventName: EventNameSchema
    .or(z.literal(''))
    .refine(val => val.length > 0, { message: 'Event name is required' }), // Necessary because '' is an allowed value
  eventType: EventSchema.shape.eventType
    .or(z.literal('')),
  eventDate: z.object({
    date: z.date()
  })
    .transform(({ date }) => convertDateToUnixTimestamp(date.toDateString()))
    // .superRefine((val, ctx) => {
    //   console.log("val in superRefine: ", val);

    //   const parsed = EventSchema.shape.eventDate.safeParse(val);
    //   if (!parsed.success) {
    //     const message = parsed.error.issues[0].message;
    //     console.log({ parsed, message });
    //     ctx.addIssue({ code: z.ZodIssueCode.custom, fatal: true, message })
    //   }
    // })
    .optional(),
})



type EventDetailsForm = z.infer<typeof EventDetailsFormSchema>;


/** This is needed to make the respective organizer fields required.  */
export const RequiredAdminSchema = EventSchema.shape.admins.element.extend({
  firstName: EventSchema.shape.admins.element.shape.firstName,
  lastName: EventSchema.shape.admins.element.shape.lastName,
  email: EventSchema.shape.admins.element.shape.email,
}).required();

export const DealerEventDetailsFormSchema = EventDetailsFormSchema.extend({
  // eventType: z.enum([EventType.Wedding, EventType.Quinceañera]),
  organizerFirstName: RequiredAdminSchema.shape.firstName,
  // .or(z.literal(''))
  // .refine(val => val.length > 0, { message: 'First Name is required' }), // Necessary because '' is an allowed value
  organizerLastName: RequiredAdminSchema.shape.lastName,
  // .or(z.literal(''))
  // .refine(val => val.length > 0, { message: 'Last Name is required' }), // Necessary because '' is an allowed value
  // organizerRole: EventSchema.shape.admins.element.shape.role.or(z.literal('')),
  organizerPhone: z.object({ phone: z.string() })
    .transform(({ phone }) => phone)
    .or(z.literal(''))
    .optional()
    .refine(val => val && val.length > 0, { message: 'Phone is required' }), // Necessary because '' is an allowed value
  organizerEmail: RequiredAdminSchema.shape.email
  // .or(z.literal(''))
  // .refine(val => val.length > 0, { message: 'Email is required' }), // Necessary because '' is an allowed value
});

export type DealerEventDetailsForm = z.infer<typeof DealerEventDetailsFormSchema>;
