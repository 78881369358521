import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import {
  EcomStyle,
  FilterCategory
} from 'common-types';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  firstValueFrom,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StyleService {
  private apiUrl = this.environment.ecom_style_rest_api_server_url;
  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  public filterCategories = new BehaviorSubject<FilterCategory[]>([]);
  public searchStyles = new Subject<void>();
  public showFilter = new BehaviorSubject<boolean>(false);
  public searchText = new BehaviorSubject<string>('');

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient
  ) {}

  private handleError(err: HttpErrorResponse): Observable<never> {
    // just a test ... more could would go here
    console.log('WE ARE HANDLING STYLE SERVICE ERROR');
    //return throwError(() => err);
    return new Observable<never>();
  }

  public getStyleByStyleCode(
    styleCode: string
  ): Observable<EcomStyle | undefined> {
    return this.httpClient
      .get<EcomStyle>(`${this.apiUrl}/styleCode/${styleCode}`, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
      .pipe(catchError(this.handleError));
  }

  public async getStyleByStyleCodeAsync(
    styleCode: string
  ): Promise<EcomStyle | undefined> {
    try {
      const response = await this.httpClient
        .get<EcomStyle>(`${this.apiUrl}/styleCode/${styleCode}`, {
          ...requireAppCheck,
          headers: this.reqHeader,
        })
        .toPromise();
      return response;
    } catch (error) {
      console.error(`Error fetching style with styleCode ${styleCode}:`, error);
      return undefined;
    }
  }

  // public getAllStyles(): Observable<EcomStyle[]> {
  //   return this.httpClient
  //     .get<EcomStyle[]>(this.apiUrl, {
  //       ...requireAppCheck,
  //       headers: this.reqHeader,
  //     })
  //     .pipe(catchError(this.handleError));
  // }

  // public getStylesByStyleType(styleType: StyleType): Observable<EcomStyle[]> {
  //   return this.httpClient
  //     .get<EcomStyle[]>(`${this.apiUrl}/styleType/${styleType}`, {
  //       ...requireAppCheck,
  //       headers: this.reqHeader,
  //     })
  //     .pipe(catchError(this.handleError));
  // }

  // public getStylesByStyleTypeAndGroup(
  //   styleType: StyleType,
  //   styleGroup: StyleGroup
  // ): Observable<EcomStyle[]> {
  //   return this.httpClient
  //     .get<EcomStyle[]>(
  //       `${this.apiUrl}/styleType/${styleType}/styleGroup/${styleGroup}`,
  //       {
  //         ...requireAppCheck,
  //         headers: this.reqHeader,
  //       }
  //     )
  //     .pipe(catchError(this.handleError));
  // }

  /** Queries EcomStyles collection and returns a list of styles from the styleCodes array that have a 'publishedDate', '>', 'eventLastUpdated',
   * or an empty array if no styles are found.
     */
  public async getOutdatedStylesByStyleCodeList(
    eventLastUpdated: string,
    styleCodes: string[] | undefined
  ): Promise<EcomStyle[] | undefined> {
    if (styleCodes === undefined || styleCodes.length <= 0) {
      return undefined;
    }

    // console.log('Orig Date:', eventLastUpdated);

    /// Need to remove millisecond level from eventLastUpdated,
    /// because EcomStyle publishedDate is NOT at millisecond level

    let lastUpdated: string = eventLastUpdated.toString().substring(0, 10);

    // console.log('LastUpdated:', lastUpdated);

    let lastUpdatedInt: number = parseInt(lastUpdated, 10);

    // console.log('LastUpdatedInt:', lastUpdatedInt);

    const styleCodeList = {
      eventLastUpdated: lastUpdatedInt,
      styleCodes: styleCodes,
    };

    try {
      return await firstValueFrom<any>(
        this.httpClient.post<any>(
          `${this.apiUrl}/getEcomStylesByStyleCodeList`,
          styleCodeList,
          {
            ...requireAppCheck,
            headers: this.reqHeader,
          }
        )
      );
    } catch (error) {
      // Handle the error here
      console.error('Error occurred during prodMode validation:', error);
      throw error; // Rethrow the error to propagate it to the caller
    }
  }

  // public getStylesByMiscellaneousType(
  //   miscellaneousType: MiscellaneousType
  // ): Observable<EcomStyle[]> {
  //   return this.httpClient
  //     .get<EcomStyle[]>(
  //       `${this.apiUrl}/miscellaneousType/${miscellaneousType}`,
  //       {
  //         ...requireAppCheck,
  //         headers: this.reqHeader,
  //       }
  //     )
  //     .pipe(catchError(this.handleError));
  // }

  // private getStylesByCategory(category: Category): Observable<EcomStyle[]> {
  //   return this.httpClient
  //     .get<EcomStyle[]>(`${this.apiUrl}/category/${category}`, {
  //       headers: this.reqHeader,
  //     })
  //     .pipe(catchError(this.handleError));
  // }

  // private getRentalStylesByCategory(
  //   category: Category
  // ): Observable<EcomStyle[]> {
  //   return this.httpClient
  //     .get<EcomStyle[]>(`${this.apiUrl}/rental/${category}`, {
  //       headers: this.reqHeader,
  //     })
  //     .pipe(catchError(this.handleError));
  // }

  // private getPurchaseStylesByCategory(
  //   category: Category
  // ): Observable<EcomStyle[]> {
  //   return this.httpClient
  //     .get<EcomStyle[]>(`${this.apiUrl}/purchase/${category}`, {
  //       headers: this.reqHeader,
  //     })
  //     .pipe(catchError(this.handleError));
  // }

  // public getStylesByStyleGroupAndCategory(
  //   styleGroup: StyleGroup,
  //   category: Category
  // ): Observable<EcomStyle[]> {
  //   try {
  //     if (category === 'ShoesAndSocks' || category === 'Shoes') {
  //       return this.getStylesByCategory(category).pipe(
  //         catchError(this.handleError)
  //       );
  //     }
  //     if (styleGroup === StyleGroup.Rental) {
  //       return this.getRentalStylesByCategory(category).pipe(
  //         catchError(this.handleError)
  //       );
  //     } else if (styleGroup === StyleGroup.Purchase) {
  //       return this.getPurchaseStylesByCategory(category).pipe(
  //         catchError(this.handleError)
  //       );
  //     } else {
  //       return this.getStylesByCategory(category).pipe(
  //         catchError(this.handleError)
  //       );
  //     }
  //   } catch (error: any) {
  //     console.log('Error occurred here for some reason');
  //     return new Observable<never>();
  //   }
  // }
}
