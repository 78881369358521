import { Inject, Injectable, OnDestroy, inject } from '@angular/core';
import { Unsubscribe } from '@angular/fire/auth';
import {
  DocumentData,
  DocumentSnapshot,
  Firestore,
  FirestoreError,
  Timestamp,
  doc,
  onSnapshot,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ECOM_EVENT_API_SERVICES } from '@jfw-library/ecommerce/api-services';
import {
  generateUuid,
  getEventSteps,
  getInStoreView,
  sizeOf,
  sizeOfDoc,
} from 'business-logic';
import {
  DealerPortalEnvironment,
  EcommerceMainEnvironment,
  Event,
  Site,
} from 'common-types';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  firstValueFrom,
  map,
  skipWhile,
} from 'rxjs';
import { IEventService } from './event.service.interface';

const FROM_ON_EVENT_SNAPSHOT = 'EventService -- onEventSnapshot';

@Injectable({
  providedIn: 'root',
})
export class EventService implements OnDestroy, IEventService {
  private dealerPortal = this.environment.site === Site.DealerPortal;

  private afs = this.environment.site === Site.Ecom ? inject(Firestore) : null;
  public selectedEvent: Event | null = null;

  /** Holds all subscriptions, except the event doc listener */
  private subscription = new Subscription();

  /** A function returned by onSnapshot() that will unsubscribe from the listener */
  private unsubscribeEventDocListener: Unsubscribe | undefined;


  private selectedEventWithUpdateContextSubject = new BehaviorSubject({
    event: {} as Event,
    isFromFirestore: false,
    matchesLastUpdateId: false,
  });

  /** For components that need to know if the "next" event value is the result of a local update or an update received from the Firestore SDK
   * This is useful for components like Checkout that need to refresh after an update is received from the Firestore SDK, but not if the update was done locally.
   * IMPORTANT: Components should NOT subscribe to both selectedEvent$ and selectedEventWithUpdateContext$ at the same time.  They should choose one or the other.
   * Subscribing to both could result in race conditions because selectedEvent$ is fed by selectedEventWithUpdateContext$.
   */
  public selectedEventWithUpdateContext$ =
    this.selectedEventWithUpdateContextSubject.asObservable().pipe(
      skipWhile(({ event }) => Object.keys(event).length === 0) /// skip the first value, which is an empty object
    );

  /** An observable of the currently selected event.
   * It is fed by selectedEventWithUpdateContext$, which is pushed new values from setSelectedEventWithEvent() */
  public selectedEvent$ = this.selectedEventWithUpdateContext$.pipe(
    map(({ event }) => event),
    skipWhile((event) => Object.keys(event).length === 0) /// skip the first value, which is an empty object
  );

  /**
   * This is set locally using the setLastUpdateId() function
   * and then sent along with certain event updates.
   * When event updates are received from the Firestore SDK,
   * the lastUpdateId is compared to the lastUpdateId in the event update.
   * This allows the client to know if an event update being received via the Firestore SDK
   * is an update that was made locally by the client or a true new update that was made elsewhere (another client, backend process, etc).
   */
  private lastUpdateId: string | null = null;

  public eventSaveError$ = new BehaviorSubject<boolean>(false);
  public transferEventError$ = new BehaviorSubject<boolean>(false);
  public canProceed$ = new Subject<boolean>();
  public nextClicked$ = new Subject<boolean>();
  public forceNextStep$ = new Subject<void>();
  public validateStep$ = new Subject<void>();

  /** True when the Event doc listener has been panicked.  Resets to false when new event is selected. */
  private eventDocListenerPanic = false;
  /** Emits true when eventDocListenerPanic is set to true. */
  private eventDocListenerPanicSubject = new BehaviorSubject<boolean>(false);
  /** Observable of whether the Event doc listener has been panicked. */
  public eventDocListenerPanic$ =
    this.eventDocListenerPanicSubject.asObservable();
  /** True when the Event doc listener is active.  False when it is inactive. */
  private eventDocListenerActive = false;
  /** Emits true when eventDocListenerActive is set to true. */
  private eventDocListenerActiveSubject = new BehaviorSubject<boolean>(false);
  /** Observable of whether the Event doc listener is active or not. */
  public eventDocListenerActive$ =
    this.eventDocListenerActiveSubject.asObservable();

  // /** Updated to now() when Event doc is received.  Resets to null on new Event subscription. */
  // private eventDocReceivedTimestamp: { seconds?: number, _seconds?: number } | null = null;
  // /** Minimum time between Event doc updates to prevent infinite loops. Will trigger unsubscribe if violated. */
  // private minTimeBetweenEventDocUpdates = 3; // seconds

  private previousUpdateTimes: Timestamp[] = [];
  private readonly eventDocListenerPanicConfig = {
    maxPreviousUpdates: 5,
    maxTimeBetweenUpdates: 20 /* seconds */,
  };
  public numEventDocUpdatesReceived = 0;
  private numEventsUpdatesReceivedSubject = new BehaviorSubject<number>(0);
  public numEventDocUpdatesReceived$ =
    this.numEventsUpdatesReceivedSubject.asObservable();

  // Stuff for assign looks temporarily
  public nextStepEventClick$ = new BehaviorSubject<string | undefined>('');

  private ecomEventApiService = this.environment?.dealerPortal
    ? inject(ECOM_EVENT_API_SERVICES.v7)
    : inject(ECOM_EVENT_API_SERVICES.v7);

  constructor(
    @Inject('environment')
    private environment: EcommerceMainEnvironment | DealerPortalEnvironment,
    private router: Router
  ) {
    console.log(
      'EventService is using Event API version: ',
      this.ecomEventApiService.apiUrl
    );

    // const eventId = localStorage.getItem('eventId');
    // if (eventId) {
    //   this.setSelectedEvent(eventId, 'EventService -- constructor');
    // }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.removeEventDocListener();
  }

  isEcommerceMainEnvironment(
    env: typeof this.environment
  ): env is EcommerceMainEnvironment {
    return env.site === Site.Ecom;
  }

  /*
   ***********************************************************************
   *                               EVENT STORE                           *
   ***********************************************************************
   */

  /**
   * Updates the selectedEvent with the provided event.
   * Sets selectedEvent, pushes a "next" value into selectedEventWithUpdateContext$ (which also pipes into selectedEvent$),
   * and stores eventId and event in localStorage
   * Note: This does NOT initialize the event listener for event updates.
   * Use setSelectedEvent() for initializing Event Service with a different event.
   * This is for updating the current selected event with a new event object.
   * */
  public setSelectedEventWithEvent(event: Event, origin: string = ''): Event {
    const date = new Date();
    const now =
      date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      }) +
      ' ' +
      date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      }) +
      '.' +
      date.getMilliseconds();
    console.log(
      `setSelectedEventWithEvent ${origin ? 'from ' + origin : ''} at ${now}`,
      event
    );

    const matchesLastUpdateId =
      this.lastUpdateId !== null &&
      event?.lastUpdateId !== undefined &&
      event.lastUpdateId === this.lastUpdateId;

    /* update_time received from onEventSnapshot is the true update_time and has the necessary .valueOf() function.
    But update_time returned from the event api is a simple object and does not have the .valueOf() function, so it needs to be re-created with Timestamp.fromMillis()   */
    if (origin !== FROM_ON_EVENT_SNAPSHOT) {
      console.log('setSelectedEventWithEvent is NOT from onEventSnapshot.');
      console.log('Formatting update_time.');
      const updateTime = event?.update_time;
      const updateTimeSeconds = updateTime?._seconds;
      const updateTimeNanoseconds = updateTime?._nanoseconds;
      const updateTimeMilliseconds = updateTimeSeconds
        ? updateTimeSeconds * 1000 + updateTimeNanoseconds / 1000000
        : null;
      const update_time = updateTimeMilliseconds
        ? Timestamp.fromMillis(updateTimeMilliseconds)
        : updateTime;
      console.log({
        updateTime,
        updateTimeSeconds,
        updateTimeNanoseconds,
        updateTimeMilliseconds,
        update_time,
      });
      this.selectedEvent = { ...event, update_time };
      this.selectedEventWithUpdateContextSubject.next({
        event: this.selectedEvent,
        isFromFirestore: false,
        matchesLastUpdateId,
      });
      // this.selectedEventSubject.next(this.selectedEvent);
    } else {
      console.log('setSelectedEventWithEvent is from onEventSnapshot.');
      console.log('Using received update_time.');
      if (matchesLastUpdateId) {
        this.lastUpdateId = null;
        console.log(
          "lastUpdateId matches event's lastUpdateId.  Resetting. now it is: ",
          this.lastUpdateId
        );
      }
      this.selectedEvent = event;
      this.selectedEventWithUpdateContextSubject.next({
        event: this.selectedEvent,
        isFromFirestore: true,
        matchesLastUpdateId,
      });
      // this.selectedEventSubject.next(event);
    }

    localStorage.setItem('eventId', event.id);
    localStorage.setItem('selectedEvent', JSON.stringify(event));
    return event;
  }

  /**
   * This is the entry point for initializing the selectedEvent.
   * It should be called any time the selectedEvent needs to be changed to a different event.
   * Fetches the event by eventId,
   * sets up the event listener for event updates (only on Ecom),
   * then calls setSelectedEventWithEvent() */
  public async setSelectedEvent(
    eventId: string,
    origin: string = ''
  ): Promise<Event> {
    console.log('setSelectedEvent eventId: ', eventId, `origin: ${origin}`);

    /// If the selectedEvent is already set to the eventId and the Event doc listener is active, return the selectedEvent without fetching from the API
    if (this.selectedEvent && this.selectedEvent.id === eventId) {
      console.log(`Event ${eventId} is already selected.`);
      if (this.environment.site === Site.Ecom && this.eventDocListenerActive) {
        console.log(
          'Event doc listener is already active.  Returning selectedEvent without fetching from API.'
        );
        return this.selectedEvent;
      } else {
        console.log(
          'Event doc listener is not active.  Fetching event from API.'
        );
      }
    }

    try {
      const event = await this.getEventById(eventId);
      localStorage.setItem('eventId', event.id);
      localStorage.setItem('selectedEvent', JSON.stringify(event));

      let eventObjSize = sizeOf(event);
      let eventDocSize = sizeOfDoc(event);
      console.log('eventObjSize: ', eventObjSize);
      console.log('eventDocSize: ', eventDocSize);

      this.setSelectedEventWithEvent(event, 'EventService -- setSelectedEvent, which itself as called by ' + origin);

      /// Setup event listener for event updates on Ecom (if enabled)
      if (
        this.environment.site === Site.Ecom &&
        this.environment.enableEventSync
      ) {
        this.setEventDocListener(eventId);
      }

      return event;
    } catch (error) {
      console.error('Error setting selected event:', error);
      throw error;
    }
  }

  /** Sets up an Event doc listener for the provided eventId */
  private setEventDocListener(eventId: string) {
    console.log("Setting up Event doc listener for eventId: ", eventId);
    if (this.environment.site !== Site.Ecom || !this.afs) {
      console.log(
        'Event doc listener is not enabled for Dealer Portal or Firestore is not available.'
      );
      return;
    }
    const ref = doc(this.afs, 'Events', eventId);
    if (this.unsubscribeEventDocListener !== undefined)
      this.unsubscribeEventDocListener();
    this.unsubscribeEventDocListener = onSnapshot(
      ref,
      this.onEventSnapshot.bind(this),
      this.onEventSnapshotError.bind(this)
    );
    // this.eventDocReceivedTimestamp = null;
    this.eventDocListenerActive = true;
    this.eventDocListenerActiveSubject.next(true);
    this.eventDocListenerPanic = false;
    this.eventDocListenerPanicSubject.next(false);
    this.previousUpdateTimes = [];
    this.numEventDocUpdatesReceived = 0;
    this.numEventsUpdatesReceivedSubject.next(0);
    console.warn(
      'Event Doc Listener is now %cACTIVE%c',
      'color: green; font-weight: bold;',
      'color: black;'
    );
  }

  /** Handles receiving updates from the Event doc
   * If the doc does not exist, unsubscribes from onSnapshot, clears selectedEvent, and routes to home page
   * If the update_time of the incoming event is different from the current selectedEvent, updates the selectedEvent and selectedEventSubject
   */
  private onEventSnapshot(doc: DocumentSnapshot<DocumentData>): void {
    console.log('Event update received:', doc);

    this.numEventDocUpdatesReceived++;
    this.numEventsUpdatesReceivedSubject.next(this.numEventDocUpdatesReceived);

    const now = Timestamp.now();
    const nowSeconds = now.seconds;

    // Add the current time to the previousUpdateTimes array
    this.previousUpdateTimes.push(now);

    const numPreviousUpdates = this.previousUpdateTimes.length;
    const firstUpdateInCurrentList = this.previousUpdateTimes[0];
    const firstUpdateSeconds = firstUpdateInCurrentList?.seconds;
    const dif = nowSeconds - firstUpdateSeconds;
    console.log({ numPreviousUpdates, nowSeconds, firstUpdateSeconds, dif });

    // Check if we need to panic and stop the event sync
    if (
      numPreviousUpdates >= this.eventDocListenerPanicConfig.maxPreviousUpdates
    ) {
      console.log(
        `There are ${this.eventDocListenerPanicConfig.maxPreviousUpdates} previous updates`
      );
      if (dif && dif < this.eventDocListenerPanicConfig.maxTimeBetweenUpdates) {
        console.warn(
          `There has been ${this.eventDocListenerPanicConfig.maxPreviousUpdates} event doc updates received within the last  ${this.eventDocListenerPanicConfig.maxTimeBetweenUpdates} seconds.  Will panic event doc listener.`
        );
        this.panicEventDocListener();
        return;
      }
    }

    // Check if specified time has passed and we can reset the previousUpdateTimes array
    if (dif > this.eventDocListenerPanicConfig.maxTimeBetweenUpdates) {
      console.log(
        `We have received ${numPreviousUpdates} update(s) of the event doc in the past ${dif} seconds.  Resetting previousUpdateTimes array.`
      );
      this.previousUpdateTimes = [];
    }

    // const currentDocSeconds = this.eventDocReceivedTimestamp !== null ? this.eventDocReceivedTimestamp?.seconds ?? this.eventDocReceivedTimestamp?._seconds ?? null : null;
    // if (currentDocSeconds !== null && currentDocSeconds < this.minTimeBetweenEventDocUpdates) {
    //   console.warn(`Event update received within ${this.minTimeBetweenEventDocUpdates} seconds of last update.  Panic.`);
    //   this.panicEventDocListener();
    //   return;
    // }

    // this.eventDocReceivedTimestamp = Timestamp.now();

    if (!doc.exists()) {
      console.warn(
        'Event does not exist.  Unsubscribing from onSnapshot, clearing selectedEvent, and routing to home page.'
      );
      this.removeEventDocListener();
      this.router.navigate(['/']).then(() => this.clearSelectedEvent());
      return;
    }

    const event = doc.data() as Event;
    // console.log('Latest event: ', event);

    /**
     * The update_time in the event doc received from the snapshot is the true update_time.
     * The update_time in the event doc returned from the event api is always slightly later than the true update_time stored in the event doc.
     * This is because the FieldValue.serverTimestamp() (used to provide a value for update_time within a transaction write) does not provide a value to the event doc returned from the api, but instead only writes the value directly to the database.
     * So Timestamp.now() is used to create a roughly equivalent update_time for the event doc that is returned from the api after an update.
     * If Timestamp.now() was not used, the update_time in the event doc returned from the api would always be just a blank object.
     * This matters because setSelectedEventWithEvent() is used to update selectedEvent$ with the event doc returned from event updates,
     * and the onEventSnapshot function will likely always be called very shortly AFTER setSelectedEventWithEvent() has already been called after the api returns
     * the new event doc after an update.  So the check below just tries to avoid calling setSelectedEventWithEvent() two times in rapid succession
     * with the same event doc:
     *   - once from the doc returned from the api,
     *   - and once from the doc received by the onEventSnapshot
     */
    const incomingUpdate_time = (event.update_time as Timestamp).valueOf();
    const currentUpdateTimeExists =
      this.selectedEvent?.update_time &&
      Object.keys(this.selectedEvent.update_time).length !== 0;
    const currentUpdate_time = currentUpdateTimeExists
      ? (this.selectedEvent?.update_time as Timestamp).valueOf()
      : null;
    const isLaterUpdate_time = currentUpdate_time
      ? incomingUpdate_time > currentUpdate_time
      : true;
    console.log({
      incomingUpdate_time,
      currentUpdate_time,
      isLaterUpdate_time,
    });

    // Some api's (like cartV2) only update lastUpdated, not update_time.  So we need to check lastUpdated as well.
    const incomingLastUpdated = event?.lastUpdated;
    const currentLastUpdated = this.selectedEvent?.lastUpdated;
    const isLaterLastUpdated =
      incomingLastUpdated && currentLastUpdated
        ? incomingLastUpdated > currentLastUpdated
        : false;
    console.log({
      incomingLastUpdated,
      currentLastUpdated,
      isLaterLastUpdated,
    });

    // Only update selectedEvent if the incoming event is different from the current selectedEvent, or if the current selectedEvent is null
    if (
      this.selectedEvent === null ||
      currentUpdate_time === null ||
      isLaterUpdate_time ||
      isLaterLastUpdated
    ) {
      // if (this.selectedEvent !== null) {
      //   const dif = getEventDif(this.selectedEvent, event, true);
      //   console.log('Event differences:', dif);
      // }

      this.setSelectedEventWithEvent(event, FROM_ON_EVENT_SNAPSHOT);
    } else {
      console.warn('Event update received but update_time is not more recent.');
      const matchesLastUpdateId =
        this.lastUpdateId !== null &&
        event?.lastUpdateId !== undefined &&
        event.lastUpdateId === this.lastUpdateId;
      if (matchesLastUpdateId) {
        this.lastUpdateId = null;
        console.log(
          "lastUpdateId matches event's lastUpdateId.  Resetting. now it is: ",
          this.lastUpdateId
        );
      }
    }
  }

  /** Handle errors from event onSnapshot Firestore SDK listener */
  private onEventSnapshotError(error: FirestoreError): void {
    console.error(
      `onEventSnapshotError:
      code: ${error.code}
      message: ${error.message}
      error: ${error}
    `
    );
    this.removeEventDocListener();
    // this.clearSelectedEvent();
  }

  /** Sets selectedEvent to null, emits selectedEventSubject with empty object, and removes eventId and selectedEvent from localStorage. */
  private clearSelectedEvent(): void {
    console.log('clear selected event');
    this.selectedEvent = null;
    this.selectedEventWithUpdateContextSubject.next({
      event: {} as Event,
      isFromFirestore: false, // false because this doesn't represent an update from the sdk
      matchesLastUpdateId: false,
    });
    // this.selectedEventSubject.next({} as Event);
    localStorage.removeItem('selectedEvent');
    localStorage.removeItem('eventId');
  }

  /** Removes the Event doc listener.  This is called when onSnapshot() returns an error or when the Event doc does not exist. */
  private removeEventDocListener(): void {
    this.unsubscribeEventDocListener?.();
    this.unsubscribeEventDocListener = undefined;
    // this.eventDocReceivedTimestamp = null;
    this.eventDocListenerActive = false;
    this.eventDocListenerActiveSubject.next(false);
    console.warn(
      'Event Doc Listener is %cINACTIVE%c',
      'color: red; font-weight: bold;',
      'color: black;'
    );
  }

  /** Disables the Event doc listener.  This will prevent any new Event doc listeners from being created. */
  public panicEventDocListener(): void {
    this.eventDocListenerPanic = true;
    this.eventDocListenerPanicSubject.next(true);
    this.removeEventDocListener();
    console.log(
      'Event Doc Listener is now %cDISABLED%c for this event.',
      'color: red; font-weight: bold;',
      'color: black;'
    );
    alert(
      'Event doc sync is now DISABLED for this event.  The Event docs will no longer automatically sync with real-time updates.  To reset, refresh the page or select the event from My Account--Events.'
    );
  }

  /** Creates a new uuid for lastUpdateId, caches it, and returns the new value.
   * This is used to track the last update received from the Firestore SDK after an update is made to the event doc.
   * @param requestedFrom The url for the request that is calling this function.  This is used for debugging purposes.
   */
  public setLastUpdateId(requestedFrom: string): string {
    const lastUpdateId = generateUuid();
    console.log(requestedFrom, 'is setting lastUpdateId to: ', lastUpdateId);
    this.lastUpdateId = lastUpdateId;
    return lastUpdateId;
  }

  /** Returns the event from cache or from api
   * If forceUpdate is true, checks to see if sdk listener is active.  If so, returns selectedEvent from cache.  Otherwise, fetches the event from the API and sets the selectedEvent.
   * If forceUpdate is false, returns selectedEvent from cache if it exists.  Otherwise, fetches the event from the API and sets the selectedEvent.
   */
  public async getSelectedEvent(
    forceUpdate: boolean = false,
    origin: string = ''
  ): Promise<Event> {
    console.log(
      `getSelectedEvent called ${origin ? `from ${origin}` : ''
      } forceUpdate: ${forceUpdate}`
    );

    // Return the cached selectedEvent if it exists and forceUpdate is false
    if (!forceUpdate && this.selectedEvent !== null) {
      console.log('getSelectedEvent is returning this.selectedEvent');
      return this.selectedEvent;
    }

    // if the the firestore sdk listener is active, return the selectedEvent (even if forceUpdate is true) bc the listener keeps selectedEvent up to date
    if (
      forceUpdate &&
      this.unsubscribeEventDocListener !== undefined &&
      this.selectedEvent !== null
    ) {
      return this.selectedEvent;
    } else {
      /* At this point we know that either:
     - forceUpdate is true but the sdk listener is not active or this.selectedEvent is null
     - forceUpdate is false but this.selectedEvent is null
    So we need to fetch the event from the API */
      const eventId = localStorage.getItem('eventId');
      if (eventId === null) {
        throw new Error('No eventId in getSelectedEvent');
      }

      try {
        console.log('getSelectedEvent is fetching event from api');
        const event = await this.getEventById(eventId);
        this.setSelectedEventWithEvent(
          event,
          'EventService -- getSelectedEvent'
        );
        return event;
      } catch (error) {
        console.error('Error in getSelectedEvent:', error);
        throw error;
      }
    }
  }

  /** Fetch event from api */
  private async getEventById(id: string): Promise<Event> {
    return (await firstValueFrom(this.ecomEventApiService.getEventById(id)))
      .event;
  }

  public routeToFirstStep() {
    if (this.selectedEvent) {
      let route = getEventSteps(this.selectedEvent, this.dealerPortal)[0].route;
      if (getInStoreView(this.selectedEvent)) {
        // check if the in store event has already been submitted
        if (
          this.selectedEvent.inStoreInfo !== undefined &&
          this.selectedEvent.inStoreInfo.dateSharedWithStore !== undefined
        ) {
          route = 'confirmation';
        }
      }
      return this.router.navigate(['/event', this.selectedEvent.id, route]);
    }
    return;
  }

  public routeToLastStep() {
    if (this.selectedEvent) {
      // 2 is subtracted from array length - last element is first step
      // of checkout, second to last element is final step of event
      const steps = getEventSteps(this.selectedEvent, this.dealerPortal);
      const route = getEventSteps(this.selectedEvent, this.dealerPortal)[
        steps.length - 2
      ].route;
      return this.router.navigate(['/event', this.selectedEvent.id, route]);
    }
    return;
  }
}
