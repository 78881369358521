import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as Auth from '@angular/fire/auth';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { Site, User } from 'common-types';
import { UserRecord } from 'firebase-admin/auth';
import { firstValueFrom } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AuthEmailActionsService } from '../auth-email-actions/auth-email-actions.service';

interface ErrorInfo {
  code: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthV1Service {
  private readonly ANON_USER_REST_API_SERVER =
    this.environment.anon_user_rest_api_server_url;
  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient,
    private authEmailActionsService: AuthEmailActionsService
  ) {}

  /** Sign up with email/password/name/phone
   * @param user user to create
   * @param site needed for the sendEmailVerification api call so that the url link matches the correct site.
   * @param verifyEmailContinueUrl optional url to navigate to after email is verified.  This is sent as the continueUrl query param for the email verification link.  If not provided, the site's default continueUrl will be used.
   */
  public createAccount(
    user: User,
    site: Site,
    verifyEmailContinueUrl?: string
  ): Promise<UserRecord> {
    console.log('createAccount...');

    return new Promise(async (resolve, reject) => {
      const moddedUser = { ...user, allowAddress: true };

      const source$ = this.httpClient
        .post<UserRecord | ErrorInfo>(
          `${this.ANON_USER_REST_API_SERVER}/new-ecom-user`,
          moddedUser,
          {
            ...requireAppCheck,
            headers: this.reqHeader,
            observe: 'response',
          }
        )
        .pipe(
          map((res) => {
            if (res.status === 201) {
              return res.body as UserRecord;
            } else {
              const error = res.body as ErrorInfo;
              throw new Error(error.message);
            }
          })
        );

      source$.subscribe({
        next: async (userRecord) => {
          try {
            const userCredential = await Auth.signInWithEmailAndPassword(
              Auth.getAuth(),
              user.email,
              user.password
            );
            userCredential.user.getIdToken(true);
            if (site === Site.DealerPortal) {
              await this.sendEmailVerification(site, verifyEmailContinueUrl);
            }
            resolve(userRecord);
          } catch (error) {
            reject(error);
          }
        },
        error: (error) => reject(error),
      });
    });
  }

  /**
   * Send email verification when new user signs up
   * @param site needed for the sendEmailVerification api call so that the url link matches the correct site.
   * @param continueUrl optional url to navigate to after email is verified.  This is sent as the continueUrl query param for the email verification link.  If not provided, the site's default continueUrl will be used.
   */
  private sendEmailVerification(
    site: Site,
    continueUrl?: string
  ): Promise<void> | undefined {
    const currentUser = Auth.getAuth().currentUser;
    if (!currentUser) return;

    return firstValueFrom(
      this.authEmailActionsService.sendEmailVerificationApi(site, continueUrl)
    )
      .then(() => {
        console.log('verification email sent');
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  public getUserAuthState(
    forceRefresh: boolean = false
  ): Promise<Auth.IdTokenResult | null> {
    return new Promise((resolve, reject) => {
      Auth.authState(Auth.getAuth())
        .pipe(first())
        .subscribe((data) => {
          data?.getIdTokenResult(forceRefresh).then((tokenData) => {
            resolve(tokenData);
          });
        });
    });
  }

  /**
   * Checks if user has ecomAccess claim on their Firebase token.
   * @param forceRefresh forces a refresh of the token regardless of token expiration.  Default is false.
   * @param ignoreWhenInProdMode if true, then this method will return true if the environment.prod_mode_authorization_rules is true.  This is useful for when you want to ignore the ecomAccess claim when in prod mode.  Default is true.
   */
  public async getUserHasEcomAccess(
    forceRefresh: boolean = false,
    ignoreWhenInProdMode: boolean = true
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (
        ignoreWhenInProdMode &&
        this.environment.prod_mode_authorization_rules
      ) {
        resolve(true);
      }
      Auth.authState(Auth.getAuth())
        .pipe(first())
        .subscribe((data) => {
          data?.getIdTokenResult(forceRefresh).then((tokenData) => {
            if (tokenData) {
              if (tokenData.claims.ecomAccess === true) {
                resolve(true);
              }
            }
            resolve(false);
          });
        });
    });
  }

  public getCurrentUser(): Auth.User | null {
    return Auth.getAuth().currentUser;
  }
}
