import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { EventAdmin, EcomEventV5Types as v5 } from 'common-types';
import { Observable } from 'rxjs';

type MembersHaveDiscontinuedStylesResponse = {
  id: string;
  membersHaveDiscontinuedStyles: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class EcomEvent_V5_ApiService {
  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  public readonly apiUrl = '/api/5/event';

  constructor(private httpClient: HttpClient) {}

  /******************************************************
   *                       CREATE                       *
   ******************************************************/

  /**
   * Create a new event.
   *
   * The dealerProcessing param should be set to the value of the environment.dealerPortal property.
   * The user param should be set to the value of the user property from the AngularFireAuth service.
   * @param data an object with properties:
   * - __event__: the event to create
   * - __organizer__: the organizer of the event
   * @returns the created event
   */
  public createEventFromDealerPortal(
    data: v5.CreateEventFromDealerPortalData
  ): Observable<v5.CreateEventFromDealerPortalSuccessResponse> {
    const route = `${
      this.apiUrl
    }/${v5.eventV5Routes.createEventFromDealerPortal.toRoute()}`;
    const numRouteArgs = v5.eventV5Routes.createEventFromDealerPortal.numArgs;
    console.log('Testing, but not using v5.eventV5Routes...', {
      route,
      numRouteArgs,
    });

    return this.httpClient.post<v5.CreateEventFromDealerPortalSuccessResponse>(
      `${this.apiUrl}/createEventFromDealerPortal`,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Create a new event.
   *
   * @param data an object with one property:
   * - __event__: the event to create
   * @returns the created event
   */
  public createEventFromEcom(
    data: v5.CreateEventFromEcomData
  ): Observable<v5.CreateEventFromEcomSuccessResponse> {
    const route = `${
      this.apiUrl
    }/${v5.eventV5Routes.createEventFromEcom.toRoute()}`;
    const numRouteArgs = v5.eventV5Routes.createEventFromEcom.numArgs;
    console.log('Testing, but not using v5.eventV5Routes...', {
      route,
      numRouteArgs,
    });

    return this.httpClient.post<v5.CreateEventFromEcomSuccessResponse>(
      `${this.apiUrl}/createEventFromEcom`,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /******************************************************
   *                        READ                        *
   ******************************************************/

  /**
   * Get an event by id.
   * @param eventId id of the event
   * @returns the event
   */
  public getEventById(
    eventId: string
  ): Observable<v5.GetEventByIdSuccessResponse> {
    return this.httpClient.get<v5.GetEventByIdSuccessResponse>(
      `${this.apiUrl}/${eventId}`,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Finds Event by eventId. Uses array of memberIds provided in body to check if any members have a discontinued item. Returns true/false
   * @param eventId the id of the event
   * @param {string[]} data.memberIds an array of memberIds from the cart
   * @returns
   */
  public hasDiscontinuedStyles(
    eventId: string,
    data: v5.HasDiscontinuedStylesData
  ): Observable<v5.HasDiscontinuedStylesSuccessResponse> {
    // const eventId = cart.eventId;

    // const memberIds: string[] = [];

    // cart.members?.forEach((member) => {
    //   memberIds.push(member.id);
    // });

    return this.httpClient.post<v5.HasDiscontinuedStylesSuccessResponse>(
      `${this.apiUrl}/${eventId}/hasDiscontinuedStyles`,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /******************************************************
   *                      UPDATE                        *
   ******************************************************/

  switchToOrderOnline(eventId: string) {
    return this.httpClient.put<v5.SwitchToOrderOnlineSuccessResponse>(
      `${this.apiUrl}/${eventId}/switchToOrderOnline`,
      {},
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Updates the event details for a specific event
   * @param eventId id of the event
   * @param data an object with new event details
   * @returns
   */
  updateEventDetails(eventId: string, data: v5.UpdateEventDetailsData) {
    return this.httpClient.put<v5.UpdateEventDetailsSuccessResponse>(
      `${this.apiUrl}/${eventId}/updateEventDetails`,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Set the memberProgress.inviteSent to the current date/time for a specific member in an event
   * @param eventId id of the event
   * @param memberId id of the member
   * @returns
   */
  markInviteSent(eventId: string, memberId: string) {
    return this.httpClient.put<v5.MarkInviteSentSuccessResponse>(
      `${this.apiUrl}/${eventId}/members/${memberId}/memberProgress/markInviteSent`,
      {},
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Updates the event.members[member].measurements for a specific member in an event
   * @param eventId id of the event
   * @param memberId id of the member
   * @param measurements the new measurements
   * @returns
   */
  updateMeasurements(
    eventId: string,
    data: v5.UpdateMeasurementsData
  ): Observable<v5.UpdateMeasurementsSuccessResponse> {
    return this.httpClient.put<v5.UpdateMeasurementsSuccessResponse>(
      `${this.apiUrl}/${eventId}/members/updateMeasurements`,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Adds a new member to an event
   * @param eventId id of the event
   * @param member the new member
   * @returns
   */
  addMember(
    eventId: string,
    data: v5.AddMemberData
  ): Observable<v5.AddMemberSuccessResponse> {
    return this.httpClient.put<v5.AddMemberSuccessResponse>(
      `${this.apiUrl}/${eventId}/members/addMember`,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Deletes a member from an event (and all associated UserEvents, unless used by another member or admin)
   * @param eventId id of the event
   * @param memberId id of the member
   * @returns
   */
  deleteMember(
    eventId: string,
    memberId: string
  ): Observable<v5.DeleteMemberSuccessResponse> {
    return this.httpClient.delete<v5.DeleteMemberSuccessResponse>(
      `${this.apiUrl}/${eventId}/members/${memberId}/deleteMember`,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Updates the member details for a specific member in an event
   * @param eventId id of the event
   * @param memberId id of the member
   * @param memberDetails the new member details
   * @returns
   */
  updateMemberDetails(
    eventId: string,
    memberId: string,
    data: v5.UpdateEventMemberDetailsData
  ): Observable<v5.UpdateEventMemberDetailsSuccessResponse> {
    return this.httpClient.put<v5.UpdateEventMemberDetailsSuccessResponse>(
      `${this.apiUrl}/${eventId}/members/${memberId}/updateMemberDetails`,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  // /**
  //  * Updates the event.isSingleUser property for a specific event
  //  * @param eventId id of the event
  //  * @param data has one property: isSingleUser.  Set to true if the event is single user, false if not
  //  * @returns
  //  */
  // updateIsSingleUser(
  //   eventId: string,
  //   data: v5.UpdateIsSingleUserData
  // ): Observable<any> {
  //   return this.httpClient.put(
  //     `${this.apiUrl}/${eventId}/updateIsSingleUser`,
  //     data,
  //     {
  //       ...requireAppCheck,
  //       headers: this.reqHeader,
  //     }
  //   );
  // }

  /**
   * Updates the event.inStoreInfo property for a specific event
   * @param eventId id of the event
   * @param data an object with the new inStoreInfo
   * @returns
   */
  updateInStoreInfo(
    eventId: string,
    data: v5.UpdateInStoreInfoData
  ): Observable<v5.UpdateInStoreInfoSuccessResponse> {
    return this.httpClient.put<v5.UpdateInStoreInfoSuccessResponse>(
      `${this.apiUrl}/${eventId}/updateInStoreInfo`,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Deletes the event.inStoreInfo.store and event.inStore.dateSharedWithStore property for a specific event
   * @param eventId id of the event
   * @returns
   */
  deleteStoreFromInStoreInfoFromEvent(
    eventId: string
  ): Observable<v5.DeleteStoreFromInStoreInfoStoreSuccessResponse> {
    return this.httpClient.delete<v5.DeleteStoreFromInStoreInfoStoreSuccessResponse>(
      `${this.apiUrl}/${eventId}/deleteInStoreInfoSelection`,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Adds a new admin to an event
   * @param eventId id of the event
   * @param coOwner the new coOwner
   * @returns
   */
  addCoOwner(eventId: string, coOwner: EventAdmin): Observable<any> {
    return this.httpClient.put(
      `${this.apiUrl}/${eventId}/admins/addCoOwner`,
      coOwner,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Adds a new admin to an event
   * @param eventId id of the event
   * @param admin the new admin
   * @returns
   */
  addAdmin(
    eventId: string,
    data: v5.AddAdminData
  ): Observable<v5.AddAdminSuccessResponse> {
    return this.httpClient.put<v5.AddAdminSuccessResponse>(
      `${this.apiUrl}/${eventId}/admins/addAdmin`,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Updates the admin details for an event admin for a specific event
   * @param eventId id of the event
   * @param adminId id of the admin
   * @param admins an object with the new admin details
   * @returns
   */
  updateAdminDetails(
    eventId: string,
    adminId: string,
    data: v5.UpdateAdminDetailsData
  ): Observable<v5.UpdateAdminDetailsSuccessResponse> {
    return this.httpClient.put<v5.UpdateAdminDetailsSuccessResponse>(
      `${this.apiUrl}/${eventId}/admins/${adminId}/updateAdminDetails`,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Adds a new look to an event
   * @param eventId id of the event
   * @param look the new look
   * @returns
   */
  addLook(eventId: string, data: v5.AddLookData) {
    return this.httpClient.put<v5.AddLookSuccessResponse>(
      `${this.apiUrl}/${eventId}/looks/addLook`,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Updates the event.members[member].memberLook for all members (as necessary) in an event
   * @param eventId id of the event
   * @param data an object with one property: lookId
   * @returns
   */
  selectLook(
    eventId: string,
    lookId: string
  ): Observable<v5.SelectLookSuccessResponse> {
    return this.httpClient.put<v5.SelectLookSuccessResponse>(
      `${this.apiUrl}/${eventId}/looks/${lookId}/selectLook`,
      {},
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Delete a look from an event.  This will remove the look from the look list and remove the look from all members
   * @param eventId id of the event
   * @param lookId id of the look
   * @returns
   */
  deleteLook(
    eventId: string,
    lookId: string
  ): Observable<v5.DeleteLookSuccessResponse> {
    return this.httpClient.delete<v5.DeleteLookSuccessResponse>(
      `${this.apiUrl}/${eventId}/looks/${lookId}/deleteLook`,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Create a duplicate of a look in an event
   * @param eventId id of the event
   * @param lookId id of the look
   * @returns
   */
  copyLook(
    eventId: string,
    lookId: string
  ): Observable<v5.CopyLookSuccessResponse> {
    return this.httpClient.put<v5.CopyLookSuccessResponse>(
      `${this.apiUrl}/${eventId}/looks/${lookId}/copyLook`,
      {},
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Assign a look to a member in an event
   * @param eventId id of the event
   * @param memberId id of the member
   * @param data an object with one property: lookId
   * @returns
   */
  assignLooks(
    eventId: string,
    data: v5.AssignLooksData
  ): Observable<v5.AssignLooksSuccessResponse> {
    return this.httpClient.put<v5.AssignLooksSuccessResponse>(
      `${this.apiUrl}/${eventId}/members/assignLooks`,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Update a look and the event.members[member].memberLook for all members that have been assigned the look in an event
   * @param eventId id of the event
   * @param look the updated look
   * @returns
   */
  updateLookAndEventMemberLooks(
    eventId: string,
    data: v5.UpdateLookAndEventMemberLooksData
  ) {
    const { look } = data;
    const { id: lookId } = look;

    return this.httpClient.put<v5.UpdateLookAndEventMemberLooksSuccessResponse>(
      `${this.apiUrl}/${eventId}/looks/${lookId}/updateLookAndEventMemberLooks`,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Updates the style in the memberLook for a specific member in an event.  Finds the style with originalStyleCode and updates it to the style associated with newStyleCode
   * @param eventId id of the event
   * @param memberId id of the member
   * @param data an object with two properties: originalStyleCode and newStyleCode
   * @returns
   */
  updateStylesInMemberLooks(
    eventId: string,
    data: v5.UpdateStylesInMemberLooksData
  ) {
    return this.httpClient.put<v5.UpdateStylesInMemberLooksSuccessResponse>(
      `${this.apiUrl}/${eventId}/members/updateStylesInMemberLooks`,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * Checks if any styles for any members in an event are rental-only or buy-only and then sets the userCartSelectedStyleGroup and styleGroup properties for those styles.
   * @param eventId id of the event
   * @returns
   */
  initializePriceSelection(eventId: string) {
    return this.httpClient.put<v5.InitializePriceSelectionSuccessResponse>(
      `${this.apiUrl}/${eventId}/members/initializePriceSelection`,
      {},
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /******************************************************
   *                      DELETE                        *
   ******************************************************/

  /**
   * Delete an event.
   * @param eventId id of the event
   * @returns
   */
  public deleteEvent(
    eventId: string
  ): Observable<v5.DeleteEventSuccessResponse> {
    return this.httpClient.delete<v5.DeleteEventSuccessResponse>(
      `${this.apiUrl}/${eventId}/deleteEvent`,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }
}
