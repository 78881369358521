import { isPlatformBrowser } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { Router } from '@angular/router';

/// authV2Service SignIn redirects an authenticated user that has not verified their email to the verify-email-address page.
/// You should not be able to access that page if you are not authenticated.
/// This guard is meant for Ecom only, not Dealer Portal because it redirects to V2login
export const verifyEmailGuard = () => {
  console.log(
    '%c* VERIFY EMAIL GUARD START *',
    'background-color: fuchsia;color:black'
  );

  const router = inject(Router);
  const auth = inject(Auth);
  const user = auth.currentUser;
  const isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  console.log(!!user ? 'user is authenticated' : 'user is not authenticated');

  // user is not authenticated, redirect to login because can't send email verification link to unauthenticated user
  if (user === null) {
    if (isBrowser) {
      alert('You must be logged in to verify your email address.');
    } else {
      console.log('verifyEmailGuard -- On Server: You must be logged in to verify your email address.');
    }
    return router.navigate(['V2login']);
  }

  const emailVerified = user.emailVerified;

  console.log(
    !!emailVerified ? 'user email is verified' : 'user email is NOT verified'
  );

  if (emailVerified) {
    //
    alert('Your email is already verified.');
  }

  // user is authenticated but email is not verified, so allow access to verify-email-address page
  return true;
};
