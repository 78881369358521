import { ServiceMap } from './src/util';

/// ************ EVENT API SERVICES ************
import {
  // EcomEvent_V2_ApiService,
  // EcomEvent_V4_ApiService,
  // EcomEvent_V5_ApiService,
  // EcomEvent_V6_ApiService,
  EcomEvent_V7_ApiService,
} from './src/event';

export const ECOM_EVENT_API_SERVICES = {
  // v2: EcomEvent_V2_ApiService,
  // v4: EcomEvent_V4_ApiService,
  // v5: EcomEvent_V5_ApiService,
  // v6: EcomEvent_V6_ApiService,
  v7: EcomEvent_V7_ApiService,
} as const satisfies ServiceMap; // as const and as satisfies ensures the object is a ServiceMap without widening the type (if the object was simply typed as ServiceMap, then it would have have a type of {[key: string]: ServiceClass<any>}, which is too wide).

export type ECOM_EVENT_API_SERVICES_VERSIONS =
  keyof typeof ECOM_EVENT_API_SERVICES;

/// ************ USER EVENT API SERVICES ************
import {
  EcomUserEvent_V2_ApiService,
  EcomUserEvent_V3_ApiService,
} from './src/user-event';

export const ECOM_USER_EVENT_API_SERVICES = {
  v2: EcomUserEvent_V2_ApiService,
  v3: EcomUserEvent_V3_ApiService,
} as const satisfies ServiceMap; // as const and as satisfies ensures the object is a ServiceMap without widening the type (if the object was simply typed as ServiceMap, then it would have have a type of {[key: string]: ServiceClass<any>}, which is too wide).

export type ECOM_USER_EVENT_API_SERVICES_VERSIONS =
  keyof typeof ECOM_USER_EVENT_API_SERVICES;

/// ************ DEALER USER EVENT API SERVICES ************
import { EcomDealerUserEvent_V3_ApiService, } from './src/dealer-user-event';

export const ECOM_DEALER_USER_EVENT_API_SERVICES = {
  v3: EcomDealerUserEvent_V3_ApiService,
} as const satisfies ServiceMap; // as const and as satisfies ensures the object is a ServiceMap without widening the type (if the object was simply typed as ServiceMap, then it would have have a type of {[key: string]: ServiceClass<any>}, which is too wide).

export type ECOM_DEALER_USER_EVENT_API_SERVICES_VERSIONS =
  keyof typeof ECOM_DEALER_USER_EVENT_API_SERVICES;

/// ************ DEALER USER API SERVICES ************
import { EcomDealerUser_V1_ApiService } from './src/dealer-user';

export const ECOM_DEALER_USER_API_SERVICES = {
  v1: EcomDealerUser_V1_ApiService,
} as const satisfies ServiceMap; // as const and as satisfies ensures the object is a ServiceMap without widening the type (if the object was simply typed as ServiceMap, then it would have have a type of {[key: string]: ServiceClass<any>}, which is too wide).

export type ECOM_DEALER_USER_API_SERVICES_VERSIONS =
  keyof typeof ECOM_DEALER_USER_API_SERVICES;

/// ************ INVITE API SERVICES ************
import {
  EcomInvite_V2_ApiService,
  EcomInvite_V3_ApiService,
} from './src/invite';

export const ECOM_INVITE_API_SERVICES = {
  v2: EcomInvite_V2_ApiService,
  v3: EcomInvite_V3_ApiService,
} as const satisfies ServiceMap; // as const and as satisfies ensures the object is a ServiceMap without widening the type (if the object was simply typed as ServiceMap, then it would have have a type of {[key: string]: ServiceClass<any>}, which is too wide).

export type ECOM_INVITE_API_SERVICES_VERSIONS =
  keyof typeof ECOM_INVITE_API_SERVICES;

/// ************ CALENDAR API SERVICES ************
import { EcomCalendar_V1_ApiService } from './src/calendar';

export const ECOM_CALENDAR_API_SERVICES = {
  v1: EcomCalendar_V1_ApiService,
} as const satisfies ServiceMap; // as const and as satisfies ensures the object is a ServiceMap without widening the type (if the object was simply typed as ServiceMap, then it would have have a type of {[key: string]: ServiceClass<any>}, which is too wide).

export type ECOM_CALENDAR_API_SERVICES_VERSIONS =
  keyof typeof ECOM_CALENDAR_API_SERVICES;

/// ************ USER VERIFICATION API SERVICES ************
import { EcomUserVerification_V1_ApiService } from './src/user-verification';

export const ECOM_USER_VERIFICATION_API_SERVICES = {
  v1: EcomUserVerification_V1_ApiService,
} as const satisfies ServiceMap; // as const and as satisfies ensures the object is a ServiceMap without widening the type (if the object was simply typed as ServiceMap, then it would have have a type of {[key: string]: ServiceClass<any>}, which is too wide).

export type ECOM_USER_VERIFICATION_API_SERVICES_VERSIONS =
  keyof typeof ECOM_USER_EVENT_API_SERVICES;
