import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {
  DealerPortalEnvironment,
  EcommerceMainEnvironment,
} from 'common-types';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { v4 as uuidv4 } from 'uuid';
import { AnonAuthService } from '../../services/anon-auth/anon-auth.service';
import { AuthV1Service } from '../../services/auth/authV1/authV1.service';
import { AuthV2Service } from '../../services/auth/authV2/authV2.service';

/**
 * Runs through a series of checks to determine if page is accessible.
 * On Ecommerce Main, all routes that are meant to be accessible by a non-logged-in user (anon user) have the AnonTokenGuard.  This guard checks for five scenarios (in this order):
 * 1. IsAuthorizedAnon - checks to see if the anonUser object exists in localStorage and has the ecomAccess custom claims.
 *    - If anonUser has ecomAccess, allow access.
 *    - Else, proceed to next step.
 * 2. IsLoggedIn - checks to see if user has ecomAccess custom claims.
 *    - If has ecomAccess, allow access.
 *    - Else, proceed to next step.
 * 3. Prod Authorization Rules Enabled - At this point the user is not logged in and/or does not have ECOM access. Time to check if Prod Auth rules take over.  If NOT environment.prod_mode_authorization_rules, the AnonToken cookies are cleared and the user is redirected to login because only users with ecomAccess claims can access the site in Prod Mode.
 * 4. Prod Auth Rules AND Allow Normal ("Wide Open") - If “Wide Open” mode, then check if all three cookies exist.  If so allow access, if not initialize a new AnonToken, then allow access.  This is the only step that allows anonymous access.
 * 5. Prod Auth Rules > Restricted - Check to see if AnonToken cookies exist:
 *    - If either AnonToken cookie does not exist, redirect to login because the AnonToken needs to be initialized by the V2login (or the user can choose to enter via /prod).
 *    - If both cookies exist, check to see if token_prod_validated cookie exists.
 *        - If it exists, it’s too soon to revalidate, so allow access.
 *        - If it doesn’t exist, it’s time to validate the token again.
 *            1. If the token is still valid, allow access.
 *            2. If the token is no longer valid, then initialize a new token.
 *                - If the token refresh is successful, allow access.
 *                - If the token refresh fails, redirect to login.
 */
@Injectable({
  providedIn: 'root',
})
export class AnonTokenGuard {
  constructor(
    @Inject('environment')
    private environment: EcommerceMainEnvironment | DealerPortalEnvironment,
    private router: Router,
    private authV1Service: AuthV1Service,
    private authV2Service: AuthV2Service,
    private cookieService: SsrCookieService,
    private anonAuthService: AnonAuthService
  ) {}

  /**
   * Returns true if:
   * 1. prod_mode_authorization_rules === true
   * 2. prod_mode_allow_normal_access === true
   * 3. prod_mode_refresh_key !== ' '
   */
  private prodModeNormal() {
    if (
      this.environment.prod_mode_authorization_rules &&
      this.environment.prod_mode_allow_normal_access &&
      this.environment.prod_mode_refresh_key !== ''
    ) {
      return true;
    }

    return false;
  }

  /**
   * Returns true if:
   * 1. prod_mode_authorization_rules === true
   * 2. prod_mode_allow_normal_access === false
   */
  private prodModeRestricted() {
    if (
      this.environment.prod_mode_authorization_rules &&
      !this.environment.prod_mode_allow_normal_access
    ) {
      return true;
    }

    return false;
  }

  /**
   *
   * @returns True if token_prod cookie exists
   */
  private tokenProdCookieExists() {
    return this.cookieService.check('token_prod');
  }

  /**
   *
   * @returns True if token_prod_session_userid cookie exists
   */
  private tokenSessionUserIdCookieExists() {
    return this.cookieService.check('token_prod_session_userid');
  }

  /**
   *
   * @returns True if token_prod_validated cookie exists
   */
  private tokenValidatedCookieExists() {
    return this.cookieService.check('token_prod_validated');
  }

  /**
   * 1. Checks if token_prod_session_userid cookie exists to use as userId.
   *    - If cookie exists, use it as userId
   *    - If cookie does not exist, generate a new uuid and use it as userId.
   * 2. Calls  [anonAuthService.initAnonToken()](../../services/anon-auth/anon-auth.service.ts) to create a new token.
   * @returns true if token was successfully initialized, false if not.
   */
  private async initAnonToken(): Promise<boolean> {
    let userId: string = '';
    if (this.tokenSessionUserIdCookieExists()) {
      userId = this.cookieService.get('token_prod_session_userid');
    } else {
      //userId = '0000-1111-2222-3333-4444'; // generateUuid();
      userId = uuidv4();
    }

    const tokenInitResponse = await this.anonAuthService.initAnonToken(userId);
    return tokenInitResponse;
  }

  /**
   * Calls the [anonAuthService.validateToken()](../../services/anon-auth/anon-auth.service.ts) to validate the AnonToken
   * (the token_prod and token_prod_session_userid cookies).
   * NOTE: This will return false if the validation attempt is too soon.
   * @returns true only if api returns a valid response (no error) and the response.valid property is true.  Otherwise returns false.
   */
  private async isValidToken(): Promise<boolean> {
    console.log('> validating anon token');

    try {
      const res = await this.anonAuthService.validateToken(
        this.cookieService.get('token_prod_session_userid'), // userId
        this.cookieService.get('token_prod') // JWT
      );

      if (res !== undefined && res && res.valid) {
        return true;
      }

      return false;
    } catch (error: any) {
      return false;
    }
  }

  /**
   * Clears the token_prod, token_prod_validated, and token_refresh_key cookies, and optionally the token_prod_session_userid cookie if deleteSessionUserIdCookie is true.
   * @param deleteSessionUserIdCookie If true, also deletes the token_prod_session_userid cookie.
   */
  private clearAnonTokenCookies(deleteSessionUserIdCookie: boolean = false) {
    this.anonAuthService.clearAnonTokenCookies(deleteSessionUserIdCookie);
  }

  /**
   * Clears the AnonToken cookies and redirects to the V2login page.
   * @returns Promise of router navigation (boolean)
   */
  private async redirectToLogin() {
    this.clearAnonTokenCookies();
    return await this.router.navigate(['V2login']);
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    /*	--------------------------------------------------
        Each "Part" checks the following main scenarios:
        1. IsAuthorizedAnon
        2. IsLoggedIn
        3. Prod Authorization Rules Enabled
        4. Prod Auth Rules AND Allow Normal ("Wide Open")
        5. Prod Auth Rules > Restricted
    --------------------------------------------------- */

    // if (this.prodModeNormal()) {
    //   resolve(true);
    //   return;
    // }

    try {
      console.log(
        '%c* ANON TOKEN GUARD START *',
        'background-color: yellow;color:black'
      );

      /// Part 1: Is AuthorizedAnon
      if (this.authV2Service.isAuthorizedAnonymous) {
        console.log('Part 1: isAuthorizedAnonymous > TRUE');
        return true;
      }

      /// Part 2: Is Logged In
      if (this.authV2Service.isLoggedIn) {
        console.log('Part 2: isLoggedIn');
        const hasEcomAccess = await this.authV1Service.getUserHasEcomAccess(
          true,
          false
        );

        if (hasEcomAccess) {
          console.log(' > ecomAccess: TRUE');
          return true;
        }

        /// If we got here, the user was logged in, but did NOT have ECOM access.
        console.log(' > User is loggedIn BUT does NOT have EcomAccess');
      }

      /* 	--------------------------------------------------
        At this point the user is not logged in and/or does not have ECOM access
        Time to check if Prod Auth rules take over
        -------------------------------------------------- */

      /// Part 3: If NOT Prod Auth Rules active, go to login because only logged in users with ecomAccess claim can access the site.
      if (!this.environment.prod_mode_authorization_rules) {
        console.log('Part 3: prodModeRules inactive > go to login');

        await this.redirectToLogin();

        return false;
      }

      // Part 4: Prod Auth Wide Open
      if (this.prodModeNormal()) {
        console.log('Part 4: Prod Mode Normal');
        // if all three cookies exist, then we are good to go
        if (
          this.tokenProdCookieExists() &&
          this.tokenSessionUserIdCookieExists() &&
          this.tokenValidatedCookieExists()
        ) {
          console.log(' > already validated');
          return true;
        }

        // Clearing token_prod_validated to prevent getToken issue
        // TODO: Reevaluate. I think this might defeat the purpose.
        // this.cookieService.delete('token_prod_validated');

        // We don't have all three cookies, so initialize with a new token
        console.log(' > refresh Anon Token');
        const initToken = await this.initAnonToken();

        console.log('InitToken');
        console.log(initToken);

        // TODO: there is no error handling or check for initToken.
        return true;
      }

      // Part 5: Prod Rules Restricted
      if (this.prodModeRestricted()) {
        console.log('Part 5: Prod Mode Restricted');

        /// If NOT token_prod or NOT userid then back to login because the user needs to either log in or initialize a new token through the prod-login page.
        if (
          !this.tokenProdCookieExists() ||
          !this.tokenSessionUserIdCookieExists()
        ) {
          console.log(' > tokenProd OR userId does not exist > go to login');
          await this.redirectToLogin();

          return false;
        }

        ///  If we are this far, we have both AnonToken cookies, so check if already validated
        if (this.tokenValidatedCookieExists()) {
          console.log(' > validatedCookie exists > PROCEED');

          return true;
        }

        /// We know that token_prod and userid should exist at this point.
        /// We also know that token_prod_validated does NOT exist, so it is not too soon to validate.
        /// Check if they are Valid.
        const isValidToken = await this.isValidToken();

        if (!isValidToken) {
          console.log(' > token is not valid ');
        }

        if (isValidToken) {
          /// Token is valid.
          console.log(' > token/userId is valid ... ');

          // Clearing token_prod_validated to prevent getToken issue
          // TODO: Reevaluate. I think this might defeat the purpose.
          // this.cookieService.delete('token_prod_validated');

          //Refresh the token information
          const initTokenResponse = await this.initAnonToken();

          if (initTokenResponse) {
            console.log(' >> token refresh successful > PROCEED ');

            return true;
          }
        }
      }

      // We got to here, so something went wrong. Go to login
      console.log('FINAL AUTH FAILURE > go to login ');
      await this.redirectToLogin();

      return false;
    } catch (error: any) {
      console.log('AnonToken Guard Error');
      console.log(error);
      return false;
    }
  }
}
