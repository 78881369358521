import { isPlatformBrowser } from '@angular/common';
import {
  Inject,
  inject,
  Injectable,
  NgZone,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';
import {
  Auth,
  AuthError,
  authState,
  browserSessionPersistence,
  getAuth,
  GoogleAuthProvider,
  idToken,
  IdTokenResult,
  Persistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  updatePassword,
  User,
  user,
} from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AfterSignIn,
  AuthErrorCode,
  DealerPortalEnvironment,
  EcommerceMainEnvironment,
  JFWNewPasswordSchema,
  PasswordParseActions,
  Site,
} from 'common-types';
import {
  firstValueFrom,
  Observable,
  shareReplay,
  Subject,
  Subscription,
} from 'rxjs';
import { EmailVerificationModalComponent } from '../../../components/email-verification-modal/email-verification-modal.component';
import { AuthEmailActionsService } from '../auth-email-actions/auth-email-actions.service';

@Injectable({
  providedIn: 'root',
})
export class AuthV2Service implements OnDestroy {
  private _currentUser: User | null = null;
  get currentUser(): User | null {
    return this._currentUser;
  }
  private set currentUser(value: User | null) {
    this._currentUser = value;
  }
  public afterSignIn$ = new Subject<AfterSignIn>();

  private readonly auth = inject(Auth);
  private readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  private readonly subscription = new Subscription();

  readonly authState$ = authState(this.auth).pipe(shareReplay());
  readonly user$: Observable<User | null> = user(this.auth);
  readonly idToken$ = idToken(this.auth);
  private customLinkId: string = '';

  constructor(
    @Inject('environment')
    private environment: EcommerceMainEnvironment | DealerPortalEnvironment,
    private authEmailActionsService: AuthEmailActionsService,
    private router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private dialog: MatDialog
  ) {
    this.setUserSubscription();
  }

  ngOnDestroy() {
    console.log('AuthV2Service ngOnDestroy');
    this.subscription.unsubscribe();
  }

  private setUserSubscription(): void {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.subscription.add(
      this.authState$.subscribe((user) => {
        if (user) {
          this.currentUser = user;
          console.log(
            'authState changed: User is logged in. Setting localStorage user to userData'
          );
          localStorage.setItem('user', JSON.stringify(this.currentUser));
        } else {
          console.log(
            "authState changed: User is logged out.  Setting localStorage user to 'null'"
          );
          localStorage.setItem('user', 'null');
        }
      })
    );
  }

  /** Removes the anonUser from localStorage */
  private deleteAnonUser(): void {
    localStorage.removeItem('anonUser');
  }

  // Preserve customLinkId in localStorage
  private setCustomLinkIdInState(): void {
    const customLinkId = localStorage.getItem('customLinkId');
    if (customLinkId) {
      this.customLinkId = customLinkId;
    }
  }

  private setCustomLinkIdLocalStorage(customLinkId: string | null): void {
    if (customLinkId) {
      localStorage.setItem('customLinkId', customLinkId)
    }
  }

  /** Sets the anonUser in localStorage */
  private setAnonUser(val: string): void {
    localStorage.setItem('anonUser', val);
  }

  /** Returns the 'anonUser' from localStorage */
  public getAnonUser(): string | null {
    return localStorage.getItem('anonUser');
  }

  /** Sign in with email/password
   *
   * @param site site that the function is being called from
   * @param email email address to sign in with
   * @param password password to sign in with
   * @param redirectUrl optional url to navigate to after sign in.  This is sent as the continueUrl query param for the email verification link when email is not verified.  If not provided, no redirect will occur.
   * @param persistence optional persistence to use for the sign in.  Defaults to browserSessionPersistence.
   * @param loginFrom optional string to indicate where the login is coming from.  Defaults to 'other'.  Used to determine if the page should be reloaded after login.  If not provided, the page will be reloaded after sign-in.
   * @param cancelRedirect cancel the redirect for sign in
   * @param emailVerifyRetryCount optional number to indicate how many times the user has tried to verify their email address in a row (over and over).  This is passed on as a query param to the email-not-verified page and used to determine if the user should be allowed to retry verifying their email address.
   * @param afterSignIn optional AfterSignIn enum to determine additional action after successful sign in
   *
   * @returns void if successful, otherwise returns an error string.
   *
   *  */
  public async signIn(
    site: Site,
    email: string,
    password: string,
    redirectUrl: string | null = null,
    persistence: Persistence = browserSessionPersistence,
    loginFrom: string = 'other',
    cancelRedirect: boolean = false,
    emailVerifyRetryCount?: number, // used to track how many times the user has tried to verify their email address in a row (over and over).  This is used to determine if the user should be allowed to retry verifying their email address.
    afterSignIn?: AfterSignIn
  ): Promise<string | void> {
    try {
      console.log('AuthV2Service.signIn');

      await this.auth.setPersistence(persistence);

      let retries = 0;

      while (retries < 2) {
        console.log('attempt', retries);

        let signInError: AuthErrorCode | string = '';

        /// ******** STEP 1: Authenticate with email/password ********
        const result = await signInWithEmailAndPassword(
          getAuth(),
          email,
          password
        ).catch((error: AuthError) => {
          console.error('signInWithEmailAndPassword error!');
          console.error(error.code);
          signInError = error.code;
        });

        console.log('signInWithEmailAndPassword result:', result);

        /// If an error occurred during signInWithEmailAndPassword, return it
        if (signInError !== '') {
          return signInError;
        }

        /// If no result, then return a generic "error".  This should never happen, but just in case...
        if (!result) {
          return 'error';
        }

        // result is defined, so no error from signInWithEmailPassword, so UserCredential exists
        const { user } = result;

        /// ******** STEP 2:  Check if email is verified, and if not, navigate to email-not-verified page or open modal ********
        if (!user.emailVerified) {
          console.log('Email not verified. ');
          if (site === Site.DealerPortal) {
            const continueUrl = redirectUrl
              ? decodeURIComponent(redirectUrl)
              : undefined;
            const retry = emailVerifyRetryCount
              ? emailVerifyRetryCount
              : undefined;
            await this.router.navigate(['/email-not-verified'], {
              queryParams: { continueUrl, retry },
              onSameUrlNavigation: 'reload',
            });
            return;
          } else {
            this.dialog.closeAll();
            const result = await firstValueFrom(
              this.dialog
                .open(EmailVerificationModalComponent, {
                  data: { email: email },
                  minWidth: '340px',
                  maxWidth: '340px',
                })
                .afterClosed()
            );
            if (result.success) {
              // sign out - sign back in will trigger authState() with email verified
              await this.signOut();
            } else {
              return;
            }
          }
        } else {
          return await this.continueSignIn(
            user,
            redirectUrl,
            loginFrom,
            cancelRedirect,
            afterSignIn
          );
        }
        retries++;
      }
    } catch (error: any) {
      console.error('Error occurred', error);
    }
  }

  private async continueSignIn(
    user: User,
    redirectUrl: string | null = null,
    loginFrom: string = 'other',
    cancelRedirect: boolean = false,
    afterSignIn?: AfterSignIn
  ): Promise<string | undefined> {
    console.log('Email is verified.  Continuing...');

    /// ******** STEP 3:  Get the user's token ********
    const token = await user.getIdTokenResult(true);

    /// If no token was found, then return a generic "error"
    if (!token) {
      return 'error';
    }

    // prod_mode_authorization_rules is set to true for all environments
    // disabling conditional logic for now

    /// ******** STEP 4:  Check if prod_mode_authorization_rules ********
    /// when prod_mode_authorization_rules is set to true in environment.prod.ts
    // if (this.environment.prod_mode_authorization_rules) {
    // console.log('entering prod_mode_authorization_rules...');
    // console.log('redirectUrl:', redirectUrl);

    if (this.environment.dealerPortal) {
      if (redirectUrl !== null) {
        console.log('TRYING TO GO TO ' + decodeURIComponent(redirectUrl));

        const decodedUrl: string = decodeURIComponent(redirectUrl);

        /// If logged in with ProdMode Restricted, redirect "Home" instead
        if (decodedUrl === '/prod' || decodedUrl === '/email-not-verified') {
          await this.router.navigateByUrl('/home').then(() => {
            if (loginFrom === 'other') {
              if (this.isBrowser) window.location.reload();
            }
          });

          return;
        }

        /// Otherwise go to the last requested Url
        await this.router
          .navigateByUrl(decodeURIComponent(redirectUrl))
          .then(() => {
            if (this.isBrowser) window.location.reload();
          });

        return;
      } else {
        // no redirectUrl, so go to home
        if (cancelRedirect === true) {
          return;
        }
        await this.router.navigateByUrl('/home').then(() => {
          if (this.isBrowser) window.location.reload();
        });

        return;
      }
    } else {
      // ecommerce
      if (afterSignIn) {
        this.afterSignIn$.next(afterSignIn);
      } else {
        // for logging in via /V2login with email/password
        if (redirectUrl) {
          await this.router.navigate([redirectUrl]);
        }
      }
      return;
    }

    // prod_mode_authorization_rules is set to true for all environments
    // so this section will never be reached

    // } // END prod_mode_authorization_rules

    /// ******** STEP 5:  Check if user has Ecom Access ********
    /// prod_mode_authorization_rules is false, so we need to check for Ecom Access
    // if (!this.hasEcomAccess(token)) {
    //   console.log('No Ecom Access');
    //   await this.signOut();

    //   // email is verified, but no Ecom Access
    //   await this.router
    //     .navigateByUrl('/V2login?error=noaccess') // verified is no longer used.  User is routed directly to the email-not-verified page.
    //     .then(() => {
    //       window.location.reload();
    //     });
    //   return;
    // } else {
    //   this.deleteAnonUser();

    //   if (redirectUrl !== null) {
    //     console.log('TRYING TO GO TO ' + decodeURIComponent(redirectUrl));
    //     await this.router.navigateByUrl(decodeURIComponent(redirectUrl));
    //     window.location.reload();
    //   } else {
    //     await this.router.navigateByUrl('/home');
    //     window.location.reload();
    //   }
    //   return;
    // }
  }

  /** Send email verification to currently logged in user */
  public async sendVerificationMail(
    site: Site,
    continueUrl?: string
  ): Promise<void> {
    const user = this.auth.currentUser;
    if (user) {
      if (continueUrl) {
        console.log(
          continueUrl
            ? 'continueUrl provided: ' + continueUrl
            : 'no continueUrl provided'
        );

        // return sendEmailVerification(user, actionCodeSettings).then(() => {
        return firstValueFrom(
          this.authEmailActionsService.sendEmailVerificationApi(
            site,
            continueUrl
          )
        ).then(() => {
          console.log(
            "Email verification sent.  Redirecting to 'email-not-verified'."
          );
          this.router.navigate(['email-not-verified']);
        });
      } else {
        // return sendEmailVerification(user).then(() => {
        return firstValueFrom(
          this.authEmailActionsService.sendEmailVerificationApi(site)
        ).then(() => {
          console.log(
            "Email verification sent.  Redirecting to 'email-not-verified'."
          );
          this.router.navigate(['email-not-verified']);
        });
      }
    } else {
      return Promise.reject('No user found');
    }
  }

  /** Returns the user from localStorage, but only if user.emailVerified is true.  Otherwise returns null. */
  public get loggedInUser(): User | null {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null && user.emailVerified !== false ? user : null;
  }

  /**
   * Returns true if there is a user in localStorage and user.emailVerified is true.
   */
  public get isLoggedIn(): boolean {
    if (
      !localStorage.getItem('user') ||
      localStorage.getItem('user') === null
    ) {
      console.log('Did not find user in localstorage');
      return false;
    }
    const user = JSON.parse(localStorage.getItem('user')!);

    // console.log('isLoggedIn:', !!user);

    const isLoggedInRetValue: boolean =
      user !== null && user.emailVerified !== false ? true : false;

    // console.log('isLoggedInRetValue');
    // console.log(isLoggedInRetValue);

    return isLoggedInRetValue;
  }

  /**
   * Returns true if the anonUser in localStorage has ecomAccess claims.
   */
  public get isAuthorizedAnonymous(): boolean {
    // console.log('AuthV2 -> isAuthorizedAnonymous -> Getting anonUser Cookie');
    const cookieVal = this.getAnonUser();
    // console.log(cookieVal);

    if (cookieVal !== undefined && cookieVal !== null) {
      const userToken: IdTokenResult = JSON.parse(
        decodeURIComponent(cookieVal)
      );

      // console.log(
      //   'AuthV2 -> isAuthorizedAnonymous -> Decoded user from anonUser Cookie'
      // );
      // console.log(user);

      // console.log('AuthV2 -> isAuthorizedAnonymous -> Checking Claims');
      if (userToken.claims !== undefined && userToken.claims !== null) {
        // console.log('AuthV2 -> isAuthorizedAnonymous -> Found Claims');
        if (userToken.claims.ecomAccess === true) {
          // console.log(
          //   'AuthV2 -> isAuthorizedAnonymous -> Has ecomAccess Claim'
          // );
          return true;
        } else {
          // console.log(
          //   'AuthV2 -> isAuthorizedAnonymous -> NO ecomAccess CLAIM!!'
          // );
        }
      } else {
        // console.log('AuthV2 -> isAuthorizedAnonymous -> NO CLAIMS FOUND!!');
      }
    }
    return false;
  }

  /** Sign in with Google */
  public async googleAuth(isAnon: boolean = false): Promise<void> {
    await this.authLogin(new GoogleAuthProvider(), isAnon);
  }

  /** Returns true if prod_mode_authorization_rules is true, or if the user has ecomAccess claims.  Otherwise returns false. */
  private hasEcomAccess(token: IdTokenResult): boolean {
    if (this.environment.prod_mode_authorization_rules) {
      return true;
    }

    if (token && token?.claims && token?.claims?.ecomAccess) {
      return token.claims.ecomAccess === true;
    }

    return false;
  }

  /**
   * Opens the sign-in popup.  After sign-in, checks if user {@link hasEcomAccess}:
   * - If no ecomAccess, signs out user and redirects to /V2login?error=noaccess and reloads the page.
   *
   * - If ecomAccess, checks if isAnon:
   *    - If isAnon, sets the anonUser in localStorage with the token of the signed-in user, then signs out of Auth and redirects to /home.
   *    - If not isAnon, removes the anonUser and isAnon from localStorage, signs out of Auth, and redirects to /home.
   *
   * Alerts user if there is an error.
   *
   * @param provider the provider to use for sign-in.  Currently only GoogleAuthProvider is supported.
   * */
  private async authLogin(
    provider: any,
    isAnon: boolean = false
  ): Promise<void> {
    console.log('authLogin');
    // return this.afAuth
    //   .signInWithPopup(provider)
    return signInWithPopup(this.auth, provider)
      .then((result) => {
        //this.SetUserData(result.user);

        result?.user?.getIdTokenResult(true).then(async (token) => {
          if (!this.hasEcomAccess(token)) {
            //this.afAuth.signOut();
            await this.signOut();
            return this.router
              .navigateByUrl('/V2login?error=noaccess')
              .then(() => {
                if (this.isBrowser) window.location.reload();
                return;
              });
            //localStorage.clear();
            //this.cookieService.removeItem('anonUser');
          } else {
            if (isAnon) {
              await this.signOut();

              // console.log('Authv2 -> AuthLogin -> SETTING anonUser Cookie:');
              // console.log('anonUser Cookie TO Set:');
              // console.log(JSON.stringify(token));

              this.setAnonUser(encodeURIComponent(JSON.stringify(token)));

              //console.log('anonUser Cookie Set (URI Encoded):');
              //console.log(this.getAnon());

              // this.router.navigateByUrl('/home').then(() => {
              //   window.location.reload();
              // });
              //console.log('Navigating to home');
              this.router.navigate(['/home']);
              //});
            } else {
              // this.removeAnonymous();

              // console.log(
              //   'Authv2 -> AuthLogin -> No Anon -> Removing anonUser Cookie:'
              // );
              // console.log(this.cookieService.getItem('anonUser'));

              // this.cookieService.removeItem('anonUser');
              //console.log(this.getAnon());
              this.deleteAnonUser();

              this.router.navigate(['home']);
              // this.router.navigateByUrl('/home').then(() => {
              //   window.location.reload();
              // });
            }
          }
        });

        //this.router.navigateByUrl('/home');
      })
      .catch((error) => {
        if (this.isBrowser) window.alert(error);
        else console.error(error);
      });
  }

  /** Updates the currently signed-in user's password.
   * @param confirmNewPassword the new password to set
   * @returns a promise of void if successful, otherwise throws an error.
   */
  public async updatePassword(confirmNewPassword: string) {
    const parsedNewPassword =
      JFWNewPasswordSchema.safeParse(confirmNewPassword);
    if (!parsedNewPassword.success) {
      const parseFailAction: PasswordParseActions = {
        success: false,
        error: parsedNewPassword.error.message,
      };
      throw parseFailAction;
    }
    const currentUser = this.auth.currentUser;
    if (currentUser) {
      try {
        // await auth.updatePassword(currentUser, confirmNewPassword);
        await updatePassword(currentUser, confirmNewPassword);
        return 'success';
      } catch (error: any) {
        console.log('UPDATE PASSWORD ERROR');
        console.log(error);
        if (error.code !== undefined) {
          console.log('error code: ' + error.code);
          return error.code;
        } else {
          // throw new Error('Password Error');
          return 'unknown-error';
        }
      }
    }
  }

  /** If there is a currently signed-in user, signs out the user and deletes the anonUser and clears localStorage.
   * If there is no currently signed-in user, clears localStorage.
   * On error, clears localStorage.
   * @returns a promise of void
   */
  public async signOut(): Promise<void> {
    // await this.afAuth.signOut(); //.then(() => {
    // Preserve the temp customLinkId in the state of the service.
    this.setCustomLinkIdInState();
    if (this.auth.currentUser) {
      return this.auth
        .signOut()
        .then(() => {
          console.log('AuthV2 -> SignOut -> Signed Out');
          localStorage.clear();
          this.setCustomLinkIdLocalStorage(this.customLinkId);
          this.deleteAnonUser();
        })
        .catch((error) => {
          console.log('error signing out');
          console.log(error);
          localStorage.clear();
        });
    } else {
      console.log(
        'AuthV2 -> SignOut -> No currentUser found.  Skipping signOut, but clearing localStorage.'
      );
      localStorage.clear();
      this.setCustomLinkIdLocalStorage(this.customLinkId);
      return;
    }

    //console.log('Authv2 -> SignOut -> Removing anonUser Cookie:');
    //console.log(this.cookieService.getItem('anonUser'));

    //this.cookieService.removeItem('anonUser');

    //console.log(this.getAnon());
    // this.deleteAnon();

    // return;
    //localStorage.removeItem('user');
    //this.router.navigate(['V2login']);
    //});
  }
}
