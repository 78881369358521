import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { EcomEventV4Types as v4 } from 'common-types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EcomEvent_V4_ApiService {
  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  public readonly apiUrl = '/api/4/event';

  constructor(private httpClient: HttpClient) {}

  /******************************************************
   *                       CREATE                       *
   ******************************************************/

  /**
   * Create a new event.
   *
   * The dealerProcessing param should be set to the value of the environment.dealerPortal property.
   * The user param should be set to the value of the user property from the AngularFireAuth service.
   * @param data an object with properties:
   * - __event__: the event to create
   * - __dealerProcessing__: Use the value of the environment.dealerPortal property.  This determines whether a UserEvent or DealerUserEvent is created.
   * - __user__: the user from the AngularFireAuth service
   * - __organizer__: the organizer of the event
   * @returns the created event
   */
  public createEvent(
    data: v4.CreateEventData
  ): Observable<v4.CreateEventSuccessResponse> {
    // const dealerProcessing = this.environment.dealerPortal;

    // const requestBody = {
    //   event,
    //   dealerProcessing,
    //   organizer,
    //   user,
    // };

    const route = `${this.apiUrl}/${v4.eventV4Routes.createEvent.toRoute()}`;
    const numRouteArgs = v4.eventV4Routes.createEvent.numArgs;
    console.log('Testing, but not using v4.eventV4Routes...', {
      route,
      numRouteArgs,
    });

    return this.httpClient.post<v4.CreateEventSuccessResponse>(
      `${this.apiUrl}/create`,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /// REMOVING SINCE IT IS NOT CURRENTLY USED.
  /**
   * Creates [numCopies] Event docs, all with the same the same data, except for a unique eventId.
   * @param event event to create
   * @param numberOfEvents number of copies of the provided event to create
   * @returns an object with property “numberOfEventsInserted”
   */
  // public insertManyEvents(numberOfEvents: number, data: v4.InsertManyEventsData): Observable<v4.InsertManyEventsResponse> {

  //   return this.httpClient.post<v4.InsertManyEventsResponse>(
  //     `${this.apiUrl}/insertManyEvents/${numberOfEvents}`,
  //     data,
  //     {
  //       ...requireAppCheck,
  //       headers: this.reqHeader,
  //     });
  // }

  /******************************************************
   *                        READ                        *
   ******************************************************/

  /**
   * Get an event by id.
   * @param eventId id of the event
   * @returns the event
   */
  public getEventById(
    eventId: string
  ): Observable<v4.GetEventByIdSuccessResponse> {
    return this.httpClient.get<v4.GetEventByIdSuccessResponse>(
      `${this.apiUrl}/${eventId}`,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /// Not currently used.
  // public getAllEventsByUserId(userId: string): Observable<GetAllEventsByUserIdSuccessResponse> {
  //   return this.httpClient.get<GetAllEventsByUserIdSuccessResponse>(
  //     `${this.apiUrl}/user/${userId}`,
  //     {
  //       ...requireAppCheck,
  //       headers: this.reqHeader,
  //     }
  //   );
  // }

  /**
   * Finds Event by eventId. Uses array of memberIds provided in body to check if any members have a discontinued item. Returns true/false
   * @param eventId the id of the event
   * @param {string[]} data.memberIds an array of memberIds from the cart
   * @returns
   */
  public hasDiscontinuedStyles(
    eventId: string,
    data: v4.HasDiscontinuedStylesData
  ): Observable<v4.HasDiscontinuedStylesSuccessResponse> {
    // const eventId = cart.eventId;

    // const memberIds: string[] = [];

    // cart.members?.forEach((member) => {
    //   memberIds.push(member.id);
    // });

    return this.httpClient.post<v4.HasDiscontinuedStylesSuccessResponse>(
      `${this.apiUrl}/has-discontinued-styles/eventid/${eventId}`,
      data,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /******************************************************
   *                      UPDATE                        *
   ******************************************************/

  /**
   * Either creates or updates an event.
   * IF (event.id && event.id.length > 3), then updateEvent, ELSE insertEvent
   * - __updateEvent:__
   *    Updates the event by using the Firestore *update* function with provided req.body and updates the lastUpdated property.
   *    Then uses Firestore *update* function to update the id, eventName, and eventDate on all UserEvent docs whose id equals eventId.
   *    Returns an object with id and event properties.
   * - __insertEvent:__
   *    Creates a new Event doc by using the Firestore *add* function with provided req.body and sets the lastUpdated and createDate properties.
   *    Returns an object with id and event properties.
   *    Does NOT create a UserEvent or DealerUserEvent doc.
   * @param event event to update (or create)
   * @returns the updated (or created) event
   */
  saveEvent(event: v4.SaveEventData): Observable<v4.SaveEventSuccessResponse> {
    console.log('saveEvent');

    const route = `${this.apiUrl}/${v4.eventV4Routes.saveEvent.toRoute(event)}`;
    const numRouteArgs = v4.eventV4Routes.saveEvent.numArgs;
    console.log('Testing, but not using v4.eventV4Routes...', {
      route,
      numRouteArgs,
    });
    // type HttpPostOptions = Parameters<HttpClient["post"]>[2];

    // const options: HttpPostOptions = {
    //   ...requireAppCheck,
    //   headers: this.reqHeader,
    // };

    return this.httpClient.post<v4.SaveEventSuccessResponse>(
      `${this.apiUrl}/save`,
      event,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /******************************************************
   *                      DELETE                        *
   ******************************************************/

  /**
   * Delete an event.
   * @param eventId id of the event
   * @returns
   */
  public deleteEventById(
    eventId: string
  ): Observable<v4.DeleteEventByIdSuccessResponse> {
    return this.httpClient.delete<v4.DeleteEventByIdSuccessResponse>(
      `${this.apiUrl}/delete/${eventId}`,
      {
        ...requireAppCheck,
        headers: this.reqHeader,
      }
    );
  }

  /**
   * 1. Finds all Event docs with matching userId (which doesn’t make sense because Event does not have a userId property).
   * 2. For each matching Event, find the UserEvent docs that have matching id to eventId.
   * 3. Delete each UserEvent doc.
   * 4. Then delete the Event doc.
   * @param userId id of the user
   * @returns
   */
  // public deleteAllByUser(userId: string): Observable<void> {
  //   return this.httpClient.delete<void>(
  //     `${this.apiUrl}/deleteAllByUser/${userId}`,
  //     {
  //       ...requireAppCheck,
  //       headers: this.reqHeader,
  //     }
  //   );
  // }
}
