import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { EventTimeline, EcomCalendarV1Types as v1 } from 'common-types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EcomCalendar_V1_ApiService {
  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private readonly apiUrl = 'api/1/calendar';

  constructor(private httpClient: HttpClient) {}

  public getEventTimeline(eventDate: number): Observable<EventTimeline> {
    const apiUrl = `${this.apiUrl}/eventTimeline/${eventDate}`;

    return this.httpClient.get<v1.GetEventTimelineSuccessResponse>(apiUrl, {
      ...requireAppCheck,
      headers: this.reqHeader,
    });
  }
}
